import * as React from 'react';
import {ProductImage} from "../../interfaces/catalog";
import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";
import {getCatalogImageUrl} from "../../util/url";
import {Image} from "../../components/image";

interface ProductImageGalleryProps {
    images: Array<ProductImage>
}

interface ProductImageGalleryState {
    lightboxIsOpen: boolean;
    selectedIndex: number;
}

export class ProductImageGalleryCarousel extends React.Component {
    constructor(public props: {
        selectedIndex: number;
        images: Array<ProductImage>;
        handleClick: (ev) => any;
        handleSelect: (ev) => any;
        thumbnail?: boolean;
    }) {
        super(props);
    }

    render () {
        const {selectedIndex, images, handleSelect, handleClick, thumbnail = false} = this.props;
        return <Carousel
            activeIndex={selectedIndex < images.length ? selectedIndex : 0}
            indicators={images.length > 1}
            controls={images.length > 1}
            fade={true}
            onSelect={handleSelect}
            slide={false} /* Disable transition animations -- at least until they're not ugly */
            interval={null} /* Disable automatic rotation */
        >
            {images.map((image, index) =>
                <Carousel.Item key={`carousel-item-${index}`}>
                    <Image url={image.url as string} thumbnail={image.thumbnail as string} caption={image.caption as string} onClick={handleClick} />
                </Carousel.Item>
            )}
        </Carousel>;
    }

}

export class ProductImageGallery extends React.Component <ProductImageGalleryProps, ProductImageGalleryState> {
    constructor(public props: ProductImageGalleryProps) {
        super(props);

        this.state = {
            lightboxIsOpen: false,
            selectedIndex: 0
        };
    }

    private openLightbox () {
        if (!this.state.lightboxIsOpen) {
            this.setState({lightboxIsOpen: true});
        }
    }

    private handleClick (ev) {
        ev.preventDefault();
        this.openLightbox();
    }

    private handleSelect (selectedIndex) {
        this.setState({selectedIndex});
    }

    private closeLightbox () {
        if (this.state.lightboxIsOpen) {
            this.setState({ lightboxIsOpen: false});
        }
    }

    render () {
        const {lightboxIsOpen, selectedIndex} = this.state;
        const images = this.props.images.filter(image => image.url);

        return <div className='product-images'>
            <div className='product-image-carousel'>
                {images.length ?
                    <ProductImageGalleryCarousel
                        selectedIndex={selectedIndex}
                        images={images}
                        handleClick={(ev) => this.handleClick(ev)}
                        handleSelect={(ev) => this.handleSelect(ev)}
                        thumbnail={true}
                    />
                    : <div className='no-image flex flex-col'>
                        <i className="material-icons">photo_camera</i>
                        No Image Available
                    </div>
                }
                {lightboxIsOpen &&
                    <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        show
                        onHide={() => this.closeLightbox()}
                        centered
                        backdropClassName='product-modal-backdrop'
                        dialogClassName='product-modal'
                    >
                        <ProductImageGalleryCarousel
                            selectedIndex={selectedIndex}
                            images={images}
                            handleClick={(ev) => this.handleClick(ev)}
                            handleSelect={(ev) => this.handleSelect(ev)}
                        />
                    </Modal>
                }
            </div>
        </div>
    }
}