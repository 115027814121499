import React from 'react';

interface StickyNavBarProps {
    className?: string;
}

export class StickyNavBar extends React.Component<StickyNavBarProps, any> {
    protected el = React.createRef<HTMLDivElement>();
    private placeholder = React.createRef<HTMLDivElement>();
    private isFloating = false;
    private observer;
    private hasChanged = false;
    private mounted = false;

    constructor(props) {
        super(props);
    }

    protected changed = () => {
        if (!this.hasChanged) {
            this.hasChanged = true;
            requestAnimationFrame(this.recalculate);
        }
    };

    protected recalculate = () => {
        if (!this.mounted) {
            return;
        }

        if (this.isOriginalBoundingBoxFullyVisible()) {
            if (this.isFloating) {
                this.stopFloating();
            }
        } else {
            this.startFloating();
        }

        this.hasChanged = false;
    };

    protected isOriginalBoundingBoxFullyVisible () : boolean {
        const {top, height} = (this.placeholder.current || this.el.current).getBoundingClientRect();
        const offset = window.innerHeight;
        const elementBounds = top + height;// + (this.isFloating ? height : 0);
        return offset > elementBounds;
    }

    protected createPlaceholderIfNecessary () {
        if (!this.placeholder.current) {
            const placeholder = document.createElement('div');
            placeholder.innerHTML = ('&nbsp;');
            placeholder.classList.add(...this.el.current.classList);
            this.el.current.insertAdjacentElement('afterend', placeholder);
            (this.placeholder as any).current = placeholder;
            placeholder.style.height = this.el.current.getBoundingClientRect().height + "px";
            placeholder.style.visibility = 'hidden';
        }
    }

    protected startFloating () {
        const el = this.el.current;
        if (!el) {
            return;
        }

        this.createPlaceholderIfNecessary();
        this.isFloating = true;

        const parentBoundingRect = (this.placeholder.current.parentNode as HTMLElement).getBoundingClientRect();

        el.classList.add('floating');
        el.style.position = 'fixed';
        el.style.bottom = '0';
        el.style.left = parentBoundingRect.left + 'px';
        el.style.width = parentBoundingRect.width + 'px';
    }

    protected stopFloating () {
        const el = this.el.current;
        if (!el) {
            return;
        }

        this.placeholder.current.remove();
        (this.placeholder as any).current = null;

        this.isFloating = false;
        el.classList.remove('floating');
        (el.parentNode as HTMLElement).style.height = '';
        el.style.position = '';
        el.style.bottom = '';
        el.style.left = '';
        el.style.width = '';
    }


    componentWillUnmount(): void {
        this.mounted = false;
        window.removeEventListener('scroll', this.changed);
        window.removeEventListener('resize', this.changed);
        this.observer?.disconnect();
    }

    componentDidMount(): void {
        this.mounted = true;
        window.addEventListener('scroll', this.changed);
        window.addEventListener('resize', this.changed);
        this.observer = new MutationObserver(this.changed);
        this.observer.observe(this.el.current.parentNode, {
            childList: true,
            subtree: true,
            attributes: false
        });

        setTimeout(this.changed);
    }

    public render () {
        return <div ref={this.el} className={this.props.className}>
            {this.props.children || null}
        </div>
    }
}

export default StickyNavBar;