export function getCookie (name: string): string | null {
    for (const cookie of document.cookie.split(';')) {
        const [cookieName, value] = cookie.trim().split('=').map(decodeURIComponent);
        if (cookieName === name) {
            return value;
        }
    }

    return null;
}

export function setCookie (name, value, expiration = new Date().setUTCFullYear(new Date().getUTCFullYear() + 1)) {
    let cookieStr = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
    if (expiration) {
        cookieStr += `; expires=${new Date(expiration).toUTCString()}`
    }
    cookieStr += '; path=/';
    document.cookie = cookieStr;
}