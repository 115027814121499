import React from "react";
import {getImageUrl} from "../../util/url";
import {Link} from "react-router-dom";

const enableNewProduct = true;

export class NewProduct extends React.Component<{}, {}> {

    public render () {
        if (!enableNewProduct) {
            return null;
        }

        return <div className='new-product'>
            <div className='section-header'>New Product!</div>
            <div className='flex flex-row new-product-row'>
                <div>
                    <Link to={"/catalog/product/KIT0001"} className='flex justify-center'>
                        <img src={getImageUrl('catalog/kits/KIT0001.jpg')} />
                    </Link>
                </div>
                <div>
                    <p>Introducing our first full kit &ndash; the <Link to={"/catalog/product/KIT0001"}>Whistle Stop platform</Link>! This model, based
                        on photos and frame captures of the prototype, is an accurate scale model of the 
                        &lsquo;Shady Rest Hotel&rsquo; whistlestop platform featured in the 1960's television 
                        series <i>Petticoat Junction</i>.</p>
                    <p>This kit features all laser-cut cedar and birch parts for precise fit and easy assembly. 
                        It is available in both 1:24 and 1:20 scales.</p>
                </div>
            </div>
        </div>
    }
}
