import React, {ReactNode} from 'react';
import {PricingConfig} from "./pricing.module";
import {usePricingConfig} from "../../network/pricing";

interface PricingProps {
    fallback?: ReactNode;
}

export const PricingContext = React.createContext<PricingConfig>(null);

export const PricingContainer: React.FC<PricingProps> = (props) => {
    const pricingConfig = usePricingConfig();

    return <PricingContext.Provider value={pricingConfig}>
        {pricingConfig ? props.children : (props.fallback || null)}
    </PricingContext.Provider>
};
