export enum Breakpoint {
    Small = "small",
    Medium = "medium",
    Large = "large"
}
export type Listener = (breakpoint: BreakpointDescription) => any;
export interface BreakpointDescription {
    name: Breakpoint;
    maxWidth: number;
    className: string;
}

const BreakpointMapping: { [key in Breakpoint]: BreakpointDescription} = {} as any;

const Breakpoints: Array<BreakpointDescription> = [
    {
        name: Breakpoint.Small,
        maxWidth: 1000,
        className: "media-small"
    },
    {
        name: Breakpoint.Medium,
        maxWidth: 1200,
        className: "media-medium"
    },
    {
        name: Breakpoint.Large,
        maxWidth: Infinity,
        className: "media-large"
    }
].map(breakpoint => BreakpointMapping[breakpoint.name] = Object.freeze(breakpoint)).sort(
    (first: BreakpointDescription, second: BreakpointDescription) => {
       if (first.maxWidth < second.maxWidth) {
           return -1;
       } else if (first.maxWidth > second.maxWidth) {
           return 1;
       }

       return 0;
    }
);

const listeners = new Set<Listener>();

export function addBreakpointListener (listener: Listener) {
    listeners.add(listener);
}

export function removeBreakpointListener (listener: Listener) {
    if (listeners.has(listener)) {
        listeners.delete(listener);
        return true;
    }

    return false;
}

let lastBreakpoint = getCurrentBreakpoint();

window.addEventListener('resize', () => {
    const currentBreakpoint = getCurrentBreakpoint();
    if (currentBreakpoint !== lastBreakpoint) {
        lastBreakpoint = currentBreakpoint;
        for (const listener of listeners) {
            try {
                listener(currentBreakpoint);
            } catch (err) {
                console.error(err);
            }
        }
    }
});

export function getCurrentBreakpoint () : BreakpointDescription | null {
    for (const breakpoint of Breakpoints) {
        if (window.innerWidth < breakpoint.maxWidth) {
            return breakpoint;
        }
    }

    return null;
}

export function getBreakpointDescription (breakpoint: Breakpoint) : Readonly<BreakpointDescription> {
    return BreakpointMapping[breakpoint];
}

export function isCurrentBreakpointGreaterThan (breakpoint: Breakpoint) {
    return getCurrentBreakpoint().maxWidth > getBreakpointDescription(breakpoint).maxWidth;
}