import React from 'react';
import {EmailType, sendMessage} from "../util/email";
import {Captcha, CaptchaTOS} from "./captcha";
import {Button} from "react-bootstrap";
import {showMessage} from "./toast/toast.module";
import {store} from "../util/redux-store";

export const MailingListForm: React.FC<any> = () => {
    const [state, setState] = React.useState({
        submitted: false,
        submitting: false,
        submitError: null
    });

    const {
        submitted,
        submitting
    } = state;

    const ref = React.useRef();

    const hasSubmitted = submitted || submitting;

    const handleSubmit = () => {
        if (!(hasSubmitted) && ref.current && (ref.current as any).value) {
            setState({
                ...state,
                submitting: true
            })
        }
    };

    const handleError = (err) => {
        store.dispatch(showMessage({
            header: "Something went wrong",
            duration: 5000,
            body: "Something went wrong trying to subscribe you to our newsletter. Please try again.",
            className: "error"
        }));

        setState({
            ...state,
            submitting: false,
            submitError: err
        });
    };

    const onVerify = (code) => {
        sendMessage(EmailType.Subscribe, code, {
            email: (ref.current as any).value
        })
        .then(() => {
            setState({
                ...state,
                submitted: true,
                submitting:  false
            });
        })
        .catch(handleError);
    };

    const message = submitted
        ? "Subscribed!"
        : submitting
            ? "Subscribing..."
            : "Subscribe";

    return <div className={'mailing-list-form'}>
        {submitting && <Captcha onVerify={onVerify} onError={handleError} />}
        <div className={'mailing-list-overview'}>
	    Interested in receiving our newsletter and update notifications for our catalog? Subscribe to our emailing list below!
        </div>
        <div className={'flex'}>
            <input className={'mailing-list-input'} type={'email'} ref={ref} name={'email'} placeholder={'Enter email'} />
            <Button onClick={handleSubmit} disabled={hasSubmitted}>{message}</Button>
        </div>
        <CaptchaTOS/>
    </div>
};
