import {rules} from "../../config/product-id";
import {resolveValues} from "./rules";
// import {Category} from "../interfaces/category";
//
// interface DoorDescription {
//     category: Category.Door;
//     custom?: boolean;
//     width: number;
//     height: number;
//     style: 'SI' | 'DO' | 'FR' | 'FD' | 'EH';
//     xPanels?: number;
//     yPanels?: number;
//     wide?: boolean;
//     sill?: 'NS' | 'FS';
//     verticalBrace?: boolean;
//     threshold?: boolean;
//     transom?: boolean;
//     trHalfMoon?: boolean;
//     trHeight?: number;
//     trPanes?: number;
//     victorianOval?: boolean;
//     extended?: string;
// }
//
// interface WindowDescription {
//     category: Category.Window;
//     custom?: boolean;
//     width: number;
//     height: number;
//     style: 'SI' | 'DO' | 'FR' | 'FD';
//     xPanes?: number;
//     yPanes?: number;
//     wide?: boolean;
//     sill?: 'NS' | 'FS';
//     verticalBrace?: boolean;
//     threshold?: boolean;
//     extended?: string;
// }
//
// const StandardCategoryMapping: {[key in Category]: string} = {
//     [Category.Door]: "DOOR",
//     [Category.Window]: "WIND"
//     //[Category.Door]: "DR",
//     //[Category.Window]: "WN"
// };
//
// const CustomCategoryMapping: {[key in Category]: string} = {
//     [Category.Door]: "DORX",
//     [Category.Window]: "WINX"
//     //[Category.Door]: "DRX",
//     //[Category.Window]: "WNX"
// };

export class ProductIdError extends Error {
}

interface BaseIdRule {
    to?: (opts: any) => string;
    from?: (...string) => any;
    optional?: boolean;
}

interface ValueIdRule extends BaseIdRule {
    values: Array<string>;
}

interface PatternIdRule extends BaseIdRule {
    pattern: RegExp;
}

export type IdRule = BaseIdRule | ValueIdRule | PatternIdRule;

type ParseRuleHandler<T extends IdRule> = (remaining: string, rule: T) => [number, Array<string>];

const handleValueRule: ParseRuleHandler<ValueIdRule> = (remaining, rule) => {
    for (let value of rule.values) {
        value = value.toUpperCase();
        if (remaining.startsWith(value)) {
            return [value.length, [value]];
        }
    }

    return [0, null];
};


const handlePatternRule: ParseRuleHandler<PatternIdRule> = (remaining, rule) => {
    const match = remaining.match(rule.pattern);
    return match ? [match[0].length, match] : [0, null];
};

const NoOpHandler: ParseRuleHandler<any> = () => [] as any;

function handleParsingRules(result, productId, rules: Array<IdRule>) {
    let remaining = productId;

    for (let rule of rules) {
        rule = resolveValues(rule, {...result});

        if (!rule) {
            continue;
        }

        if (Array.isArray(rule)) {
            [, remaining] = handleParsingRules(result, remaining, rule);
            continue;
        }

        let handler: ParseRuleHandler<any>;
        if ((rule as ValueIdRule).values) {
            handler = handleValueRule;
        } else if ((rule as PatternIdRule).pattern) {
            handler = handlePatternRule;
        } else {
            handler = NoOpHandler;
        }

        const [consumed, value] = handler(remaining, rule);

        if (value === null) {
            if (!rule.optional) {
                throw new ProductIdError("Could not satisfy required rule: " + JSON.stringify(rule, null, 4));
            }
        } else {
            remaining = remaining.substr(consumed);
            const parsed = rule.from?.apply(result, value);
            if (parsed && typeof parsed === 'object') {
                Object.assign(result, parsed);
            }
        }
    }

    return [result, remaining];
}

export function parseProductId(productId: string) {
    const result = {};
    try {
        handleParsingRules(result, productId.toUpperCase(), rules as Array<IdRule>);
    } catch (err) {
        if (!(err instanceof ProductIdError)) {
            console.error("An unexpected error occurred while generating product id: " + err.message, err);
        }
        throw err;
    }
    for (const key of Reflect.ownKeys(result)) {
        if (result[key] === undefined || result[key] === null) {
            delete result[key];
        }
    }
    return result;
}

function handleGenerationRules(opts: any, rules: Array<IdRule>) {
    let result = '';
    for (let rule of rules) {
        rule = resolveValues(rule, opts);

        if (!rule) {
            continue;
        }

        if (Array.isArray(rule)) {
            result += handleGenerationRules(opts, rule);
            continue;
        }

        if (!rule.to) {
            continue;
        }


        result += rule.to.call(opts, opts);
    }

    return result;
}

export function generateProductId(options) {
    return handleGenerationRules({...options}, rules as Array<IdRule>);
}
