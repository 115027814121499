import {addReducer} from "../../util/redux-store";
import { get} from '../../util/object-utils';
const Keyspace = "pricing";
/************* Interfaces **************/

interface SetPricingInfoAction {
    type: ActionTypes.SetConfig;
    payload: PricingConfig;
}

export interface PricingConfigItem {
    price?: number;
    assemblyPrice?: number;
    priceBaseDoor: number;
    priceBaseWindow: number;
    priceWide: number,		// material surcharge if width >48"
    priceDoubleDoor: number;
    priceEngineHouseDoor: number;
    priceFreightDoor: number;
    priceTransom: number;
    pricePerSidelight: number;		// each
    pricePediment: number;

    // Assembly:
    baseAssemblyPrice: number;
    assemblyFreight: number;
    assemblyDouble: number;
    assemblyWide: number;		// width >48"
    assemblyOversized: number;		// single up-charge if any are included
    assemblyPediment: number;
    assemblyComplex: number;
}

export type PricingConfig = { [ key: string ]: PricingConfigItem; } & {
    overrides: {
        [key: string] : {
            [key: string] : {
                price: number;
                assemblyPrice: number;
            }
        }
    }
}

/************* Actions **************/

enum ActionTypes {
    SetConfig = "PRICING/SET_CONFIG"
}

export const setPricingConfig = (config: PricingConfig) => (
    {
        type: ActionTypes.SetConfig,
        payload: config
    } as SetPricingInfoAction
);

/************* SELECTORS **************/

export const getPricingConfig = (state) => get(state, Keyspace) as Readonly<PricingConfig>;

/************* Reducer **************/


const InitialState: PricingConfigItem = null;

const reducer = (state = InitialState, action: SetPricingInfoAction) => {
    switch (action?.type) {
        case ActionTypes.SetConfig:
            return {...action.payload};
    }

    return state;
};

addReducer(reducer, Keyspace);