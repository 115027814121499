import {getSendMessageApi} from "./url";

export enum EmailType {
    ProductInquiry = 'productInquiry',
    Subscribe = 'subscribe',
    Order = 'order'
}

export interface SubscribePayload {
    email: string;
}

export interface ProductInquiryPayload {
    name: string;
    email: string;
    phone?: string;
    message: string;
}

export interface OrderPayload {
    name: string;
    email: string;
    phone: string;
    order: any;
}

export async function sendMessage (type: EmailType.Order, code: string, payload: OrderPayload);
export async function sendMessage (type: EmailType.ProductInquiry, code: string, payload: ProductInquiryPayload);
export async function sendMessage (type: EmailType.Subscribe, code: string, payload: SubscribePayload);
export async function sendMessage (type: EmailType, code: string, payload: any) {
    try {
        const response = await fetch(getSendMessageApi(), {
            method: "post",
            mode: 'cors',
            body: JSON.stringify({
                code,
                type,
                payload
            })
        });

        if (response.status >= 400) {
            throw new Error("Unexpected status: " + response.statusText);
        }
    } catch (err) {
        console.error("Could not send message: " + err.message, err);
        throw new Error("Could not send message: " + err.message);
    }
}