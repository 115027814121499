import * as React from "react";
import {getCatalogImageUrl} from "../util/url";
import {MouseEventHandler} from "react";

export class Image extends React.Component<{url: string, thumbnail?: string, caption?: string, onClick?: MouseEventHandler}, any> {
    constructor(props) {
        super(props);
    }

    render () {
        const {url, thumbnail, caption, onClick} = this.props;

        let toRender = <img src={getCatalogImageUrl((thumbnail || url) as string)} onClick={onClick} />;

        if (caption) {
            toRender = <div className={'image-wrapper'}>
                <div className={'image'}>{toRender}</div>
                <div className={'image-caption'}>{caption}</div>
            </div>;
        }

        return toRender;
    }
}