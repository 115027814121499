import {getCookie, setCookie} from "./cookie";

export enum StoreName {}
export enum PersistentStore {
    Attributes = 'attributes',
    Cart = 'cart'
}

const PersistentStoreCookieName = 'user-data';

interface IStore<K=any,V=any> {
    get: (key: K) => V | null;
    set: (key: K, value: V) => void;
    entries: () => IterableIterator<[K,V]>;
    toJSON: () => any;
}

class LocalStore<K=any,V=any> implements IStore<K,V> {
    protected store = new Map();

    constructor (obj?) {
        if (obj) {
            for (const key of Reflect.ownKeys(obj)) {
                this.store.set(key, obj[key]);
            }
        }
    }

    get (key) {
        return this.store.get(key);
    }

    set (key, value) {
        this.store.set(key, value);
    }

    entries (): IterableIterator<[K,V]> {
        return this.store.entries();
    }

    toJSON () {
        const result = {};
        for (const [key, value] of this.entries()) {
            result[String(key)] = value;
        }
        return result;
    }
}

class MasterPersistentStore<K=any,V=any> extends LocalStore<K, V> {
    constructor () {
        super();
        let loaded: any = getCookie(PersistentStoreCookieName);
        if (loaded) {
            try {
                loaded = JSON.parse(loaded);
                for (const keyspace of Reflect.ownKeys(loaded)) {
                    const subObj = loaded[keyspace];
                    if (typeof subObj === 'object') {
                        super.set(keyspace, new ChildPersistentStore(this, subObj));
                    }
                }
            } catch (err) {
                console.error("Could not parse user preferences: ", err.message);
            }
        }
    }

    get (key) {
        if (!this.store.has(key)) {
            super.set(key, new ChildPersistentStore(this));
        }
        return super.get(key);
    }

    protected persist () {
        setCookie(PersistentStoreCookieName, JSON.stringify(this));
    }
}

class ChildPersistentStore<K=any,V=any> extends LocalStore<K,V> {
    private master;

    constructor(
        master: MasterPersistentStore,
        initial?: any
    ) {
        super(initial);
        this.master = master;
    }

    set (key, value) {
        super.set(key, value);
        this.master.persist();
    }
}

const persistentStore = new MasterPersistentStore();

export function getPersistentStore (name: PersistentStore): IStore {
    return persistentStore.get(name);
}