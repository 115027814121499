import * as React from 'react';
import {getImageUrl, getStaticUrl, getCatalogImageUrl} from "../util/url";

interface ProductsViewProps {
    match: {
        params: {
            productsName?: string
        }
    }
}


interface ProductsViewState {
    productsName?: string;
}

export class ProductsView extends React.Component<ProductsViewProps, ProductsViewState> {
    constructor (public props: ProductsViewProps) {
        super(props);
    }

    render () {
        const {productsName} = this.props.match.params;
		if (productsName == 'doors') {
			return <div className={'products-view'}>
				<div className='section-header'>Doors</div>
				<div className='section-content'>
					<p>
					Standard doors are available in 1:24 and 1:20 scale, with other scales 7/8" (1:13.7) through 1:32 possible as custom. We make these doors from multiple layers of aircraft-grade birch ply. (We highly recommend treating all wood products with a sealant/preservative if used out-of-doors.)
					</p>
					<p>
					A "standard" door is typically 36"x80" (in 1:1 scale), although some manufactures of scale model doors have used a narrower 32" width. With Eckardt Design you can use whatever door size makes sense for your design. Freight and warehouse loading doors would be much larger, maybe 96-100" wide and 108" tall. And of course you can have a double standard-width door.
					</p>
					<p>
					The major difference between doors styles, other than size, is the door construction. The most common is a solid paneled door, often 4- or 5-panels vertically, or sometimes multiple horizontal panels such as a 2x4 paneled door. A single panel or 1x2 paneled door with cross-bracing is a popular alternative.
					</p>
					<p>
					Another variant is a door with a window in the top panel, often used in offices and Victorian era houses.
					</p>
					<div className={'center'}><img src={getImageUrl('DOOR3280SI14.jpg')} className={'splash-image'} /></div>
					<p>
					Sometimes a transom is desired &ndash; basically a window set above the door in a single frame. This was very common for turn-of-the-centural American buildings. Sidelights, one side the door or both, is also available.
					</p>
					<div className={'center'}><img src={getImageUrl('door_secondary_full.jpg')} className={'splash-image'} /></div>
					<p>
					Various pediment options are available as special orders.
					</p>
					<p>
					Our doors and windows are provided as a simple-to-assemble kits (see the assembly instructions <a href={getStaticUrl('window_kit_assembly_instructions.pdf')}>here</a>), or may optionally be ordered pre-assembled.
					</p>
					<p>
					Please see the Request Quote page for a full list of the options available.
					</p>
				</div>
		    </div>;
	} else if (productsName == 'windows') {

	    return <div className={'products-view'}>
			<div className='section-header'>Windows</div>
			<div className='section-content'>
			    <p>
				Standard window are available in 1:24 and 1:20 scale; other scales 7/8" (1:13.7) through 1:32 are also possible as custom orders. We make these windows from multiple layers of aircraft-grade birch ply. (We highly recommend treating all wood models with a sealant/preservative if used out-of-doors.)
			    </p>
			    <p>
				Windows are available in fixed and single- or double-hung styles. While it is typical to have the same number of panes in both the upper and lower sashes, the upper sash can be a single pane to match 1900-era depots, etc.
			    </p>
			    <div className={'center'}><img src={getImageUrl('window_samples.jpg')} className={'splash-image'} /></div>
			    <p>
				The window may have any number of panes required, with 3x4 or 3x6 being the most popular.
			    </p>
			    <p>
				Compound windows &ndash; two windows in a single frame &ndash; are possible.
			    </p>
			    <p>
				Various pediment options are available as special orders.
			    </p>
			    <p>
				Our doors and windows are provided as a simple-to-assemble kits (see the assembly instructions <a href={getStaticUrl('window_kit_assembly_instructions.pdf')}>here</a>), or may optionally be ordered pre-assembled.
			    </p>
			    <p>
				Please see the Request Quote page for a full list of the options available.
			    </p>
			</div>
		    </div>;
	} else if (productsName == 'crates') {

	    return <div className={'products-view'}>
			<div className='section-header'>Crates</div>
			<div className='section-content'>
			    <p>
				What better to add interest to your depot or freight station platform, or to provide visible loads for your rolling stock?  We offer wooden packing crates in various shapes and sizes in 1:20 and 1:24 scales.
			    </p>
			    { /*
			    <div style="display: flex; justify-content: center">
			    */ }
			    <div className={'center'}><img src={getImageUrl('crates_group_sd.jpg')} className={'splash-image'} /></div>
			    { /*
			    </div>
			    */ }
			    <p>
				We highly recommend treating all wood products with a sealant/preservative if used out-of-doors.
			    </p>
			</div>
		    </div>;
	}
	else if (productsName == 'wagonwheels') {

	    return <div className={'products-view'}>
			<div className='section-header'>Wagon Wheels</div>
			<div className='section-content'>
			    <p>
				Our wagon wheels are currently only available in 1:20 and 1:24 scales, although please inquire if you need another scale and we'll see what we can do.
			    </p>
			    { /*
			    <div style="display: flex; justify-content: center">
			    */ }
			    <div className={'center'}>
				    <img src={getImageUrl('wagon_wheels_group_sd.jpg')} className={'splash-image'} />
				</div>
			    { /*
			    </div>
			    */ }
			    <p>
				The wagon wheels are available in a range of diameters (currently 34", 42", 38", 50", and 54"), widths, and number of spokes (10, 12, 14, and 16 spokes). As a wagon will typically use different sizes of wheels on front and back, it is difficult to offer "standard" wheel sets that will meet everyone's needs. Please contact us with your requirements and we'll provide a quote for exactly what you need.
			    </p>
			    <p>
				All of our wagon wheels are designed to be press-fitted onto a 1/16" brass rod for an axle.
			    </p>
			</div>
		    </div>;
	}
	else if (productsName == 'kits') {

	    return <div className={'products-view'}>
			<div className='section-header'>Kits</div>
			<div className='section-content'>
			    <p>
                                We offer a new line of complete laser-cut kits. These kits are made from cedar and birch stock, and are designed for ease of assembly yielding a museum-quality result.
                            </p>
                            <p>
                                Our first kit offering is for a small whistle stop platform. Many more kits will be added soon!
			    </p>
			    { /*
			    <div style="display: flex; justify-content: center">
			    */ }
			    <div className={'center'}>
                                <img src={getCatalogImageUrl('kits/KIT0001.jpg')} className={'splash-image'} />
				</div>
			    { /*
			    </div>
			    */ }
			    <p>
                                Currently these are available only in 1:20 and 1:24 scales &ndash; please inquire if you need another scale.
			    </p>
			</div>
		    </div>;
	}
	else {
	    return <div>Unknown product type {productsName}</div>;
	}


    }
}
