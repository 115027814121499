import {Category} from "../src/interfaces/category";
import {lpad} from "../src/util/string-utils";
import {ProductIdError} from "../src/util/product-id";
import { debug } from "console";

const ParamConstants = {
    ro: 1.75,           // 0.0833"
    roSlop: .25,
    casing: 4,
    wideCasing: 6,
    trRail: 4,
    fuzz: .0005,
};

function required<T = any>(value, key: string): T {
    if (typeof value === 'object') {
        value = value[key];
    }
    if (!value && !Number.isFinite(value)) {
        throw new ProductIdError("Missing required key: " + key);
    }

    return value;
}

function fixCategory(category: string) {
    category = category.substr(0, 2).toUpperCase();

    switch (category) {
        // correct old-style categories to new categories
        case 'DO':
            category = Category.Door;
            break;
        case 'WI':
            category = Category.Window;
            break;
        case 'KI':
            category = Category.Kit;
            break;
    }

    return category;
}

function getScaleBase (scale: number) {
    switch (scale) {
        case 13:
            return 13.7;
        case 20:
            return 20.32;
        case 22:
            return 22.5;
        default:
            return scale;
    }
}

export const rules = [
    //
    // Parse id
    //
    {
        values: [Category.Window, Category.Door, Category.Kit, 'CR', 'WW', 'KI', 'DOOR', 'WIND', 'DOR', 'WIN'],
        from: (category: string) => {
            return {category: fixCategory(category)};
        },
        to: (opts) => {
            return fixCategory(required<string>(opts, 'category'));
        }
    },
    {
        values: ['X'],
        optional: true,
        from: () => ({custom: true}),
        to: opts => opts.custom ? 'X' : ''
    },
    {
        $if: `{category !== "${Category.Kit}"}`,
        $then: {
            pattern: /([0-9]{2})+/,
            from: (match: string) => ({
                width: Number(match.substr(0, match.length / 2)),
                height: Number(match.substr(match.length / 2))
            }),
            to: opts => {
                let width, height;
                if (opts.sizingType === "roughOpening") {
                    //
                    // Calculate width and height based upon RO inputs
                    //
                    const roInputWidth = required(Number(opts.roInputWidth), 'roInputWidth');
                    const roInputHeight = required(Number(opts.roInputHeight), 'roInputHeight');

                    width = roInputWidth * required(opts, 'scale') - 2 * (opts.ro + opts.roSlop);
                    width -= (opts.sidelight === 'SL' ? (opts.slWidth + (opts.wide === "W" ? opts.wideCasing : opts.casing)) : 0);
                    width -= (opts.sidelight === 'SR' ? (opts.slWidth + (opts.wide === "W" ? opts.wideCasing : opts.casing)) : 0);
                    width -= (opts.sidelight === 'SB' ? 2 * (opts.slWidth + (opts.wide === "W" ? opts.wideCasing : opts.casing)) : 0);
                    if (opts.style === 'DO') {
                        width /= 2;
                    }
                    width = Math.round(width);

                    height = roInputHeight * required(opts, 'scale') - ((opts.category === Category.Door ? 1 : 2) * (opts.ro + opts.roSlop));
                    height -= (opts.transom ? (opts.trHeight + opts.trRail) : 0);
                    height = Math.round(height);

                    let temp = 0;       // dummy line for breakpoint target
                } else {
                    //
                    // Explicit width and height given
                    //
                    width = required(Number(opts.width), 'width');
                    height = required(Number(opts.height), 'height');
                }
                const padLength = Math.max(width, height) > 99 ? 3 : 2;
                return `${lpad(width, padLength)}${lpad(height, padLength)}`;
            }
        }
    },
    {
        $if: `{category === "${Category.Kit}"}`,
        $then: {
            pattern: /([0-9]{4})/,
            from: (match: string) => ({
                kitNum: Number(match)
            }),
            to: opts => lpad(opts.kitNum, 4)
        },
        $else: {
            values: {
                $switch: '{category}',
                $case: {
                    [Category.Door]: ['SI', 'DO', 'FR', 'FD', 'EH', 'ER'],
                    [Category.Window]: ['DH', 'SH', 'FI', 'FB', 'DB']
                }
            },
            from: (style) => ({style}),
            to: opts => required(opts, 'style')
        }
    },
    {
        $if: {
            category: Category.Door,
        },
        $then: {
            pattern: /([0-9])([0-9])/,
            from: (match, xPanels, yPanels) => ({
                xPanels: Number(xPanels),
                yPanels: Number(yPanels)
            }),
            to: opts => `${required(opts, 'xPanels')}${required(opts, 'yPanels')}`
        }
    },
    {
        $if: {
            category: Category.Window,
        },
        $then: {
            pattern: /([0-9])([0-9])/,
            from: (match, xPanes, yPanes) => ({
                xPanes: Number(xPanes),
                yPanes: Number(yPanes)
            }),
            to: opts => `${required(opts, 'xPanes')}${required(opts, 'yPanes')}`
        }
    },
    {
        values: ["A"],
        optional: true,
        from: () => ({asymmetric: true}),
        to: opts => opts.asymmetric ? 'A' : ''
    },
    {
        values: ["W"],
        optional: true,
        from: () => ({wide: true}),
        to: opts => opts.wide ? 'W' : ''
    },
    {
        $if: {
            category: Category.Window
        },
        $then: {
            values: ["NS", "FS"],
            optional: true,
            from: sill => ({sill}),
            to: opts => opts.sill || ''
        }
    },
    {
        $if: {
            category: Category.Door
        },
        $then: {
            pattern: /(NT)?/,
            optional: true,
            from: (noThreshold) => ({threshold: !noThreshold}),
            to: opts => opts.threshold === false ? 'NT' : ''
        }
    },
    {
        values: ['VB'],
        optional: true,
        from: () => ({verticalBrace: true}),
        to: opts => opts.verticalBrace ? 'VB' : ''
    },
    {
        pattern: /TR(?:(HM)|(?:([0-9]{2})([0-9])?)?)/,
        optional: true,
        from: function ( match, halfMoon, trHeight, trPanes) {
            return {
                transom: true,
                ...(halfMoon
                        ? {
                            transom: "HM",
                            trHalfMoon: true,
                            trHeight: Number(this.width/2),
                            trPanes: 4
                        }
                        : {
                            trHeight: Number(trHeight) || 12,
                            trPanes: Number(trPanes) || 2
                        }
                )
            };
        },
        to: opts => {
            if (!opts.transom) {
                return '';
            }
            let result = 'TR';
            if (opts.trHalfMoon) {
                result += 'HM';
            } else if (required(opts, 'trHeight') !== 12 || required(opts, 'trPanes') !== 2) {

                result += `${lpad(opts.trHeight, 2)}${opts.trPanes}`;
            }
            return result;
        }
    },
    {
        pattern: /(SL|SR|SB)(?:(?:([0-9]{2})([0-9])?)?)/,
        optional: true,
        from: function (match, sidelight, slWidth, slPanes) {
            return {
                sidelight,
                slWidth: Number(slWidth) || 12,
                slPanes: Number(slPanes) || this.yPanels,
            }
        },
        to: opts => {
            if (!opts.sidelight) {
                return '';
            }
            let result = opts.sidelight;
            if (required(opts, 'slWidth') !== 12 || required(opts, 'slPanes') !== opts.yPanels) {
                result += `${lpad(opts.slWidth, 2)}${opts.slPanes}`;
            }
            return result;
        }
    },
    {
        values: ['PPD', 'PBR', 'POP', 'PSG', 'PBS', 'PWS'],
        optional: true,
        from: pediment => ({pediment}),
        to: opts => opts.pediment || ""
    },
    {
        pattern: /D(VO|DR|DL|DB|IC|G[1-6])([0-9]{0,2})/,
        optional: true,
        from: (match, pdType, brWidth) => ({
            pdType,
            pdBrWidth: Number(brWidth) || undefined
        }),
        to: opts => opts.pdType ? `D${opts.pdType}${opts.pdBrWidth || ""}` : "",
    },
    {
        pattern: /R(V|D)([0-1]*[0-9]{0,1})/,
        optional: true,
        from: (match, rSlatDir, rSlatWidth) => ({
            rSlatDir,
            rSlatWidth: Number(rSlatWidth) || undefined
        }),
        to: opts => opts.rSlatDir ? `R${opts.rSlatDir}${opts.rSlatWidth || ""}` : "",
    },
    {
        pattern: /X([^-]*)/,
        optional: true,
        from: (match, extended) => extended ? ({extended}) : null,
        to: opts => opts.extended ? `X${opts.extended}` : ""
    },
    {
        $if: `{category === "${Category.Kit}"}`,
        $then: {
            pattern: /V([0-9])/,
            optional: true,
            from: (match, variant) => ({variant}),
            to: opts => opts.variant ? `V${opts.variant}` : ""
        }
    },
    {
        pattern: /-/,
        optional: false,
        from: (match) => null,
        to: opts => '-'
    },
    {
        pattern: /[0-9]{2}/,
        from: scale => {
            scale = Number(scale);

            return {
                scale,
                scaleBase: getScaleBase(scale)
            };
        },
        to: opts => Number(required(opts, 'scale'))
    },
    //
    // end of id parsing
    //


    //
    // Calculation of height, width, overallHeight, overallWidth, and other values based
    // on id
    //
    {
        //
        // Include the door/window constants
        //
        from: function () {
            Object.assign(this, ParamConstants);
        }
    },

    //
    // Calculate values based on id. This includes for the makers during entry.
    //
    {
        from: function () {
            //
            // Simply reference to casingWidth
            //
            const casingWidth = this.wide ? this.wideCasing : this.casing;

            //
            // Calculate overallWidth and overallWidthActual
            //
            this.overallWidth = (this.style === 'DO' ? this.width * 2 : this.width) + 2 * casingWidth;
            if (this.sidelight) {
                const slWidth = this.slWidth + casingWidth;
                this.overallWidth += (this.sidelight === 'SB' ? 2 * slWidth : slWidth);
            }
            this.overallWidthActual = this.overallWidth / this.scaleBase;

            //
            // Calculate overallHeight and overallHeightActual
            //
            if (this.category === Category.Door) {
                this.overallHeight = this.height + casingWidth;
            } else {
                this.overallHeight = this.height + 2 * casingWidth;
            }
            this.overallHeight += (this.transom ? this.trHeight + this.trRail : 0);
            this.overallHeightActual = this.overallHeight / this.scaleBase;

            //
            // Calculate the rough opening width
            //
            this.roWidth = ((this.style === 'DO' ? this.width * 2 : this.width) / this.scaleBase) + (2 * (this.ro + this.roSlop) / this.scaleBase);
            if (this.sidelight) {
                const slWidth = (this.slWidth + casingWidth) / this.scaleBase;
                this.roWidth += (this.sidelight === 'SB' ? 2 * slWidth : slWidth);
            }

            //
            // Calculate the rough opening height
            ///
            switch (this.category) {
                case Category.Door:
                    this.roHeight = (this.height / this.scaleBase) + ((this.ro + this.roSlop) / this.scaleBase);
                    break;
                case Category.Window:
                    this.roHeight = (this.height / this.scaleBase) + (2 * (this.ro + this.roSlop) / this.scaleBase);
                    break;
            }

            this.roHeight += (this.transom ? (this.trHeight + this.trRail) / this.scaleBase : 0);
        }
    },
    {
        //
        // Estimate material used (in sq inches)
        //
        // These estimates take into consideration that an item consists of multiple
        // layers plus some extra material for the carrier. Wastage is not included.
        //
        from: function () {
            this.materialUsed = this.overallWidthActual * this.overallHeightActual;
            switch (this.category) {
                case Category.Door:
                    if (this.style === 'EH' || this.style === 'ER') {
                        this.materialUsed *= 3.3;
                    } else {
                        this.materialUsed *= 5.45;
                    }
                    break;
                case Category.Window:
                    this.materialUsed *= 4.7;
                    break;
            }
        }
    }
];
