import {CategoryDescription} from "../interfaces/category";
import {CatalogCategory} from "../interfaces/catalog";

export const Categories: Array<CategoryDescription> = [
    {
        displayName: "Windows",
        name: CatalogCategory.Windows,
        description: "These are windows"
    },
    {
        displayName: "Doors",
        name: CatalogCategory.Doors,
        description: "These are doors"
    },
    {
        displayName: "Kits",
        name: CatalogCategory.Kits,
        description: "These are kits"
    },
//    {
//        displayName: "Wagon Wheels",
//        name: CatalogCategory.WagonWheels,
//        description: "Wagon Wheels"
//    },
//    {
//        displayName: "Packing Crates",
//        name: CatalogCategory.PackingCrates,
//        description: "Packing crates"
//    }
];

const categoryMap = {};
for (const category of Categories) {
    categoryMap[category.name] = category;
}

export function getCategoryDescription (name: string): CategoryDescription | null {
    return categoryMap[name] || null;
}
