import * as React from "react";
import {getImageUrl} from "../util/url";

interface Question {
    title: string;
    answer: string;
}

interface Category {
    name: string;
    questions: Array<Question>
}

interface FAQState {
    expanded: {
        [key: string]: boolean
    };
}

const categories: Array<Category> = [
    {
        name: "Product Details",
        questions: [
            {
                title: "What are the doors and windows made out of?",
                answer: "All our doors, windows, and packing crates are made from aircraft-grade Baltic birch plywood."
            },
            {
                title: "Can these doors and windows be used outdoors?",
                answer: "Our doors and windows are made from high-quality aircraft-grade birch plywood, and will" +
                    " stand up to the elements reasonably well. But like any wood structure, they will age with" +
                    " time when exposed to the weather. As with all outdoor wood structures, we recommend they" +
                    " be finished or sealed in some manner to protect them and extend their life. Thompson" +
                    " Waterseal works well if you want a natural wood finish. They can, of course, be painted or" +
                    " stained. We have also had good luck dipping them in slightly thinned water-based polyurethane" +
                    " before mounting and glazing them. In summary, treat and protect them as you would any wood" +
                    " structure left outdoors."
            },
            {
                title: "How many parts are in a typical window kit?",
                answer: "A typical window is made up of six parts that need to be glued together, but this can vary" +
                    " from kit to kit. See typical instructions on resources page."
            },
            {
                title: "How difficult is it to assemble a window or door kit?",
		answer: "A typical door or window takes about 10 minutes to assemble. This, of course, varies with" +
		    " your experience, and your first few will likely take you a bit longer until you get the hang" +
		    " of it."
            },
            {
		title: "I don't have the time or inclination to assemble door or window kits. Are they available" +
		    " pre-assembled?",
		answer: "YES! We can provide you assembled versions of most of our kits at a nominal extra cost."
	    },
        ]
    },
    {
        name: "Assembly and Finishing",
        questions: [
            {
                title: "What type of glue should I use to assemble the door or window kits?",
		answer: "Any good wood glue will work; we prefer Titebond II as excess glue can easily be cleaned" +
		    " up with a damp sponge or cloth, yet it's weatherproof once fully dried. Whatever glue you" +
		    " use, use it sparingly and clean up any glue ooze as you go. We recommed that you put the" +
		    " assembled door or window under a weight until the glue fully dries to ensure the assembled" +
		    " door or window dries flat."
            },
            {
                title: "Do the doors, windows, and kits need to be finished in some manner?",
                answer: "Our products are made of high-quality wood, and should be finished and protected as" +
                    " as you would any wood model. If you plan to use them outdoors, we recommend they" +
                    " be finished or sealed in some manner to protect them from the elements and extend their" +
                    " life. Thompson Waterseal works well if you want a natural wood finish. They can, of course," +
                    " be painted or stained. We have also had good luck dipping them in slightly thinned" +
                    " water-based polyurethane before glazing and mounting them."
            },
        ]
    },
    {
        name: "Problems",
        questions: [
            {
                title: "My kit arrived with a broken part. What should I do?",
		answer: "All efforts are made to ensure your kit arrives in perfect condition, but if a piece" +
		    " does arrive broken, please contact us and we'll send a replacement part. We may ask you to return" +
		    " the broken part or to email us a photo of the broken part so we can make sure we send the" +
		    " correct part."
            },
        ]
    }
];

function generateKey (categoryName: string, index: string | number) {
    return `${categoryName}-${index}`;
}

export class FaqView extends React.Component <{}, FAQState> {
    constructor (props) {
        super(props);
        this.state = {
            expanded: {}
        };
    }

    toggle (key) {
        this.setState({
            ...this.state,
            expanded: {
                ...this.state.expanded,
                [key]: !this.state.expanded[key]
            }
        });
    }

    render () {
        const {expanded} = this.state;

        return <div className={'FAQs-view'}>
            <div className='section-header'>FAQs</div>
            <div className='section-content'>
                {categories.map(category =>
                    <div className={'faq-category'}>
                        <h3>{category.name}</h3>
                        <div className={"questions"}>
                            {category.questions.map((question, index) => {
                                const key = generateKey(category.name, index);
                                const toggled = !!expanded[key];
                                return <React.Fragment>
                                    <div className={"question"} onClick={() => this.toggle(key)}>
                                        <i className={'material-icons'}>{toggled ? 'remove' : 'add'}</i>
                                        {question.title}
                                    </div>
                                    <div className={`answer ${toggled ? "" : " hidden"}`}>{question.answer}</div>
                                </React.Fragment>
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    }
}
