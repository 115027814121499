import * as React from 'react';
import {CatalogSidebar} from "./catalog-view/catalog-sidebar";
import {Categories} from '../util/categories';
import {ProductView} from "./product-view";
import {CategoryView} from "./category-view";

interface CategoryViewProps {
    match: {
        params: {
            categoryId?: string;
            productId?: string;
        }
    }
}


interface CategoryViewState {
    categoryId?: string;
    productId?: string;
}

export class CatalogView extends React.Component<CategoryViewProps, CategoryViewState> {
    render () {
        const {categoryId, productId} = this.props.match.params;

        return <div className="catalog">
            <CatalogSidebar categories={Categories} active={categoryId} />
            {productId
                ? <ProductView productId={productId}/>
                : <CategoryView categoryId={categoryId}/>
            }
        </div>;

    }
}