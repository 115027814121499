import {Link} from "react-router-dom";
import * as React from "react";
import {CategoryDescription} from "../../interfaces/category";
import {getCatalogCategoryUrl} from "../../util/url";

interface CatalogSidebarProps {
    categories: Array<CategoryDescription>,
    active?: string;
}

export class CatalogSidebar extends React.Component {
    constructor (public props: CatalogSidebarProps) {
        super(props);
    }

    render () {
        const {active} = this.props;
        //Needs an outer div wrapper so that the content doesn't automatically scale to 100%
        return <div>
            <div className='catalog-sidebar'>
                <ul>
                    {this.props.categories.map(category =>
                        <li className={category.name === active ? 'active' : 'inactive'} key={category.name}>
                            <Link to={getCatalogCategoryUrl(category.name)}>
                                <span className={"catalog-sidebar-item-title"}>{category.displayName}</span>
                                <i className="material-icons">keyboard_arrow_right</i>
                            </Link>
                        </li>
                    )}
                </ul>
            </div>
        </div>

    }
}
