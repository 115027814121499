import {addReducer} from "../../util/redux-store";
import {deepEquals, get} from '../../util/object-utils';
import {getPersistentStore, PersistentStore} from "../../util/persistent-store";
import {parseProductId} from "../../util/product-id";

const Keyspace = "cart";
/************* Interfaces **************/

interface CartAction {
    type: ActionTypes;
    payload: CartItem;
}

export interface CartItem {
    product: string;
    quantity: number;
    display?: Array<string>;
    price: number;
    metadata?: any;
    image?: string;
}

interface CartState {
    products: Readonly<Array<Readonly<CartItem>>>;
}

/************* Actions **************/

enum ActionTypes {
    AddItem = "CART/ADD_ITEM",
    EmptyCart = "CART/EMPTY",
    UpdateItem = "CART/ADJUST_ITEM"
}

export const updateCartItem = (item: {product: string} & Partial<CartItem>) => (
    {
        type: ActionTypes.UpdateItem,
        payload: item
    }
);

export const addCartItem = (item: CartItem) => (
    {
        type: ActionTypes.AddItem,
        payload: item
    }
);

export const emptyCart = () => (
    {
        type: ActionTypes.EmptyCart
    }
);

/************* SELECTORS **************/

export const getCart = (state) => get(state, Keyspace) as Readonly<CartState>;
export const getCartItems = (state) => {
    return (getCart(state)?.products || []) as Readonly<Array<Readonly<CartItem>>>;
};

/************* Reducer **************/
const persistentStore = getPersistentStore(PersistentStore.Cart);

function validateCartItem (item: CartItem) {
    try {
        parseProductId(item.product);
    } catch (err) {
        return false;
    }
    return Number.isFinite(item.quantity) && item.quantity > 0 &&
        (!item.image || typeof item.image === 'string');
}

const InitialState: CartState = persistentStore.get(Keyspace) || {
    products: []
};
InitialState.products = InitialState.products.filter(validateCartItem);

const reducer = (state = InitialState, action: CartAction) => {
    let nextState;
    switch (action?.type) {
        case ActionTypes.EmptyCart:
            nextState = {
                products: []
            };
            break;
        case ActionTypes.AddItem:
        case ActionTypes.UpdateItem:
            let updated = false;
            const nextProducts = [];

            for (const item of state.products) {
                if (item.product === action.payload.product && deepEquals(item.metadata, action.payload.metadata)) {
                    updated = true;

                    if (action.type === ActionTypes.UpdateItem && !action.payload.quantity) {
                        //Remove item.
                        continue;
                    }

                    const nextProduct = {
                        ...item,
                        ...action.payload,
                        quantity: action.type === ActionTypes.UpdateItem ? action.payload.quantity : item.quantity + action.payload.quantity
                    };

                    if (validateCartItem(nextProduct)) {
                        nextProducts.push(nextProduct);
                    }
                } else {
                    nextProducts.push(item);
                }
            }

            if (!updated) {
                nextProducts.push(action.payload);
            }

            nextState = {
                ...state,
                products: nextProducts
            };
    }

    if (nextState) {
        persistentStore.set(Keyspace, nextState);
        return nextState;
    }

    return state;
};

addReducer(reducer, Keyspace);