import {createStore, Reducer} from 'redux';
import {set, get} from './object-utils';

interface RegisteredReducer {
    reducer: Reducer;
    storePath: string;
}

enum ActionTypes {
    ReducerAdded = 'STORE/REDUCER_ADDED'
}

const registeredReducers: Array<RegisteredReducer> = [];

export function addReducer (reducer: Reducer, storePath: string) {
    registeredReducers.push({
        reducer,
        storePath
    });

    store.dispatch({
        type: ActionTypes.ReducerAdded,
        reducer
    });
}

const reducer = (state, action) => {
    for (const {reducer, storePath} of registeredReducers) {
        set(state, storePath, reducer(get(state, storePath), action));
    }

    return {...state};
};

export const store = createStore(reducer);