import {Link} from "react-router-dom";
import {getImageUrl} from "../util/url";
import {Navbar, Tab} from "./navbar";
import * as React from "react";

interface AppHeaderProps {
    tabs: Array<Tab>;
}

export const AppHeader: React.FC<AppHeaderProps> = ({tabs}) => <>
    <div className={'logo'}>
        <Link to={"/"}><img src={getImageUrl('web_logo.gif')}/></Link>
    </div>
    <div className={'banner'}>
        <img src={getImageUrl('banner.svg')}/>
    </div>
    <Navbar tabs={tabs}/>
</>;