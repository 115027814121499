import * as React from "react";

interface ErrorViewProps {
    code: number;
    error?: Error;
}


export const ErrorView: React.FC<ErrorViewProps> = (props) => {
    const {error, code} = props;

    return <div>
        Error {code}
        {error ? <pre>{error.message}\n{error.stack}</pre> : null}
    </div>
};