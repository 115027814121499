import * as React from "react";
import {Product} from "../../interfaces/catalog";
import {getCatalogImageUrl, getCatalogProductUrl} from "../../util/url";
import {Link} from "react-router-dom";
import {resolveValues} from "../../util/rules";

export class CatalogItem extends React.Component {
    constructor (public props: { item: Product, context: any}) {
        super(props);
    }

    render () {
        const {item, context} = this.props;
        const resolved = resolveValues(item, context);
        const productUrl = getCatalogProductUrl(resolved.id);
        const image = resolved.images?.[0];
        const thumbnailUrl = image && (image.thumbnail || image.url);
        return <div className="catalog-item">
            <div className='catalog-item-image'>
                <Link to={productUrl}>
                    {thumbnailUrl ?
                        <img src={getCatalogImageUrl(thumbnailUrl)} /> :
                        <React.Fragment>
                            <i className="material-icons">photo_camera</i>
                            <div>
                                No Image Available
                            </div>
                        </React.Fragment>
                    }
                </Link>
            </div>
            <div className={'catalog-item-title'}>
                <Link to={productUrl}>
                    {resolved.title}
                </Link>
            </div>
        </div>
    }
}
