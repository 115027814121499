import * as React from "react";
import {useCallback, useContext, useEffect, useState} from "react";
import {ProductAttribute, ProductAttributeOption, ProductSelectAttribute} from "../../interfaces/catalog";

import Form from "react-bootstrap/Form";
import {toCurrencyFormat} from "../../util/currency";
import {validate, ValidationState} from "../../util/validate";
import {debounce} from "../../util/debounce";

type BeforeSubmitCallback = (context: any) => ValidationState;

export const FormContext = React.createContext<{
    beforeSubmitHooks: Array<BeforeSubmitCallback>;
    registerBeforeSubmitHook: (fn: BeforeSubmitCallback) => any;
    unregisterBeforeSubmitHook: (fn: BeforeSubmitCallback) => any;
}>(null);

export const useForm = () => {
    const [beforeSubmitHooks] = useState<Array<BeforeSubmitCallback>>([]);

    const registerBeforeSubmitHook = React.useCallback((fn) => {
        if (!beforeSubmitHooks.includes(fn)) {
            beforeSubmitHooks.push(fn);
        }
    }, [beforeSubmitHooks]);

    const unregisterBeforeSubmitHook = React.useCallback((fn) => {
        const index = beforeSubmitHooks.indexOf(fn);
        if (!~index) {
            return;
        }

        beforeSubmitHooks.splice(index, 1);
    }, [beforeSubmitHooks]);

    return {
        beforeSubmitHooks,
        registerBeforeSubmitHook,
        unregisterBeforeSubmitHook
    };
};

interface ProductAttributeFormProps {
    attributes: Array<ProductAttribute>;
    context: object;
    onChange: (attribute: ProductAttribute, value: any, state: ValidationState) => any;
}

function findSelectedValue (attribute: ProductSelectAttribute, context: any) {
    let result = context[attribute.name];

    if (result === null || result === undefined) {
        const defaultOption = attribute.options.find(option => option.default);
        result = defaultOption && defaultOption.value;
    }

    return result;
}

function getValue (option) {
    if (typeof option === 'object') {
        if (option) {
            return option.value;
        }
    } else {
        return option;
    }
}

interface BaseProductAttributeInputProps <V = any> {
    attribute: ProductAttribute;
    context: any;
    onChange: (attribute: ProductAttribute, value: V) => any;
}

type ProductAttributeInputProps <V = any> = Omit<BaseProductAttributeInputProps<V>, 'onChange'> & {
    onChange: (attribute: ProductAttribute, value: V, validationState: ValidationState) => any;
};

interface SimpleProductAttributeInputProps <V = any> extends BaseProductAttributeInputProps <V> {
    as?: string;
    invalid?: boolean;
}

function getCostInfoString (option, isAssemblyEnabled) {
    const price = (option.price || 0) + ((isAssemblyEnabled ? option.assemblyPrice : 0) || 0);

    return price ? ` (+${toCurrencyFormat(price)})` : '';
}

const SimpleAttributeInput: React.FC<SimpleProductAttributeInputProps> = (props) => {
    const {attribute, as = 'input', onChange, invalid} = props;
    const placeholder = attribute.placeholder || `Enter ${attribute.label}`;

    const debounced = debounce((ev) => {
       onChange(ev, ev.target?.value);
    }, 350);

    const handleChange = (ev) => {
        ev.preventDefault();
        debounced(ev.nativeEvent);
    };

    const onBlur = useCallback((ev) => {
        onChange(ev, ev.target?.value);
    }, [attribute, onChange]);

    return <Form.Control
        as={as as any}
        type={attribute.type}
        placeholder={placeholder}
        isInvalid={invalid}
        onChange={handleChange}
        onBlur={onBlur}
    />;
};

interface ProductAttributeSelectInputProps extends BaseProductAttributeInputProps {
    attribute: ProductSelectAttribute;
    invalid?: boolean;
}

const ProductAttributeSelectInput: React.FC<ProductAttributeSelectInputProps> = (props) => {
    const {onChange, attribute, context, invalid} = props;
    const handleChange = (ev) => {
        ev.preventDefault();
        const selectedOption = attribute.options.find(option => String(getValue(option)) === ev.target.value);
        const value = typeof selectedOption === 'object' ? selectedOption?.value : selectedOption;
        onChange(ev, value);
    };

    const selectedValue = findSelectedValue(attribute, context);
    const placeholder = attribute.placeholder || `Select ${attribute.label}`;
    return  <Form.Control
        as="select"
        placeholder={placeholder}
        value={selectedValue}
        isInvalid={invalid}
        onChange={handleChange}
    >
        {selectedValue === undefined &&
        <option>{placeholder}</option>
        }
        {attribute.options.map((option: ProductAttributeOption, index) => {
            let value, displayValue;
            if (typeof option === 'object') {
                value = option.value;
                displayValue = option.label || value;
            } else {
                value = displayValue = option;
            }

            return <option
                value={value}
                key={`${(attribute as ProductSelectAttribute).name}-${index}`}
            >{displayValue}{getCostInfoString(option, context.assembled)}</option>
        })}
    </Form.Control>
};

const ProductAttributeInput: React.FC<ProductAttributeInputProps> = (props) => {
    const {attribute, onChange, context} = props;
    const [errors, setErrors] = useState<Array<string> | null>([]);
    const [lastValue, setLastValue] = useState();
//    const formContext = useContext(FormContext);

    const handleValidate = React.useCallback((value, ignoreEmptyValues = false) => {
        if (ignoreEmptyValues && value === "") {
            if (errors.length > 0) {
                setErrors([]);
            }
            return ValidationState.Invalid;
        }
        const validationErrors = validate(attribute, value); //Not sure this is the right "this" to use.
        if (validationErrors.join("\n") !== errors.join("\n")) {
            setErrors(validationErrors);
        }

        return validationErrors.length === 0 ? ValidationState.Valid : ValidationState.Invalid;
    }, [errors, attribute]);

    const handleChange = React.useCallback((ev, value) => {
        ev.preventDefault();

        if (value === "") {
            value = undefined;            
        }

        if (value === lastValue) {
            return;
        }

        const validationState = handleValidate(value, true);
        setLastValue(value);

        onChange(attribute, value, validationState);
    }, [lastValue, handleValidate]);

    // useEffect(() => {
    //     formContext.registerBeforeSubmitHook(beforeSubmitHook);
    //     return function () {
    //         formContext.unregisterBeforeSubmitHook(beforeSubmitHook);
    //     }
    // }, [beforeSubmitHook]);

    if (!attribute?.name) {
        return null;
    }

    let input;

    const commonProps = {
        attribute,
        context,
        onChange: handleChange,
        invalid: errors.length > 0
    };

    switch (attribute.type || "select") {
        case 'select':
            input = <ProductAttributeSelectInput {...commonProps} attribute={commonProps.attribute as ProductSelectAttribute}/>;
            break;
        case 'textarea':
            input = <SimpleAttributeInput as={'textarea'} {...commonProps} />;
            break;
        default:
            input = <SimpleAttributeInput {...commonProps} />;
            break;
    }

    return <Form.Group controlId={attribute.name}>
        <Form.Label>{attribute.label}</Form.Label>
        {input}
        <Form.Control.Feedback type="invalid">
            {errors.map((error, index) => <React.Fragment key={'error-' + index}><span className={'error'}>{error}</span><br /></React.Fragment>)}
        </Form.Control.Feedback>
        {attribute.comment ? <div className={'attribute-comment'}>{attribute.comment}</div> : null}
    </Form.Group>
};

const preventSubmit = (ev) => ev?.preventDefault();

export const ProductAttributeForm: React.FC<ProductAttributeFormProps> = (props) => {
    const {attributes, context, onChange} = props;
    return <>
        {attributes?.length ?
            <div className="product-attributes">
                <div className={'overview'}>
                    Select desired attributes to see additional product information:
                </div>
                <Form noValidate onSubmit={preventSubmit}>
                    {attributes.map((attribute, index) =>
                      <ProductAttributeInput
                          key={ `attribute-${attribute.name}-${index}`}
                          attribute={attribute}
                          onChange={onChange}
                          context={context}
                      />
                    )}
                </Form>
            </div>
            : null
        }
    </>;
};
