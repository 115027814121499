type ClassArg = undefined | null | string | Array<string> | { [key: string]: boolean | (() => boolean) };
export const classNames = (...classes: Array<ClassArg>) => {
    const result = [];
    for (const item of classes) {
        if (typeof item === 'string') {
            result.push(item);
        } else if (Array.isArray(item)) {
            result.push(...item);
        } else if (item && typeof item === 'object') {
            for (const key of (Reflect.ownKeys(item) as Array<string>)) {
                let value = item[key];
                if (value instanceof Function) {
                    value = value();
                }

                if (value) {
                    result.push(key);
                }
            }
        }
    }

    return result.join(" ");
};