import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CartItem, emptyCart, getCartItems, updateCartItem} from "../components/cart/cart.module";
import {Link, Redirect} from "react-router-dom";
import {
    getCatalogUrl,
    getCatalogImageUrl,
    getOrderCompletedUrl
} from "../util/url";
import {toCurrencyFormat} from "../util/currency";
import {ContactForm, Field} from "../components/contact-form";
import {EmailType} from "../util/email";
import {calculateCartItemPrice} from "../util/price-utils";
import {isCustomized} from "../util/custom";
import {usePricingConfig} from "../network/pricing";
import {Spinner} from "../components/spinner";
import { processTags } from '../util/tag-processor';

const DefaultMaxQuantity = 25;

interface CartViewProps {}

function createOptions(quantity: number) {
    const options = [];
    for (let i = 1; i <= Math.max(quantity, DefaultMaxQuantity); i++) {
        options.push(<option value={i} key={`option-${i}`}>{i}</option>);
    }

    return options;
}

const CartList: React.FC<{}> = () => {
    const cartItems = useSelector(getCartItems);
    const dispatch = useDispatch();

    const updateCart = useCallback((item: CartItem, quantity: number) => {
        dispatch(updateCartItem({
            ...item,
            quantity
        }));
    }, [dispatch]);

    return <div className={'cart-items'}>
        <div className={'cart-item header'}>
            <div className={'product-image'}>{' '}</div>
            <div className={'product-quantity'}>Quantity</div>
            <div className={'product-price'}>Subtotal</div>
            <div className={'product-subtotal'}>Description</div>
        </div>
        {cartItems.map((item, index) => <div className={'cart-item'} key={`cart-item-${index}`}>
            <div className={'product-image'}>
                {item.image
                    ? <img src={getCatalogImageUrl(item.image)}/>
                    : null
                }
            </div>
            <div className={'product-quantity'}>
                <div>
                    <select onChange={(ev) => updateCart(item, Number(ev.target.value))} value={item.quantity}>
                        {createOptions(item.quantity)}
                    </select>
                </div>
                <div className={'cart-remove'}>
                    <a onClick={(ev) => {
                        ev.preventDefault();
                        updateCart(item, 0);
                    }}>(remove)</a>
                </div>
            </div>
            <div className={'product-price'}>
                <span className={'subtotal'}>{toCurrencyFormat(calculateCartItemPrice(item))}</span>
            </div>
            <div className={'product-description'}>
                <div>
                    {item.display
                        ?.filter(line => line) //Remove empty lines
                        .map((line, index) => <div key={index}>{processTags(line)}</div>)
                    }
                </div>
            </div>
        </div>)}
    </div>;
};


export const CartView: React.FC<CartViewProps> = (props) => {
    const [completed, setCompleted] = useState(false);
    const cartItems = useSelector(getCartItems);
    const dispatch = useDispatch();

    const onSubmit = useCallback(() => {
        dispatch(emptyCart());
        setCompleted(true)
    }, [setCompleted, dispatch]);

    const pricingConfig = usePricingConfig();

    if (!pricingConfig) {
        return <Spinner />
    }

    let total = 0;
    let customized = false;
    for (const item of cartItems) {
        total += calculateCartItemPrice(item);
        customized = customized || isCustomized(item.metadata);
    }

    return <div className={'cart-view'}>
        {cartItems.length
            ? <React.Fragment>
                <div className={'flex'}>
                    {/*An extra wrapper is required for FireFox to keep the table from overlapping*/}
                    <div className={'w-100'}>
                        <CartList />
                    </div>
                    <div className={'checkout flex flex-col'}>
                        <div className={'checkout-top-block'}>
                            Clicking on "Submit" below is a request for a quote only; it is not a commitment 
                            to order. After we have reviewed your order, you will receive an email with any 
                            questions or clarifications we might have, final pricing, and payment instructions.
                        </div>
                        <div className={'total'}>
                            Estimate*: {toCurrencyFormat(total)}
                        </div>
                        <ContactForm
                            fields={[Field.Name, Field.Email, Field.ZipCode]}
                            data={{order: cartItems}}
                            messageType={EmailType.Order}
                            submitButtonSendingText={"Processing..."}
                            submitButtonText={customized ? "Request a Quote" : "Submit Request"}
                            submitButtonSubmittedText={"Request Received!"}
                            submitErrorText={"Your order could not be sent. Please try again."}
                            onSubmit={onSubmit}
                        />
                    </div>
                </div>
                <div className='footer flex center'>
                    * This estimate does not include shipping/taxes. We will confirm the order and provide 
                    a final quote after we review your request.
                </div>
            </React.Fragment>
            : <div className="empty-cart"><span>You currently have no items in your cart. Why not <Link
                to={getCatalogUrl()}>browse through our catalog?</Link></span></div>
        }
        {completed
            ? <Redirect to={getOrderCompletedUrl()} push={true}/>
            : null
        }
    </div>
};
