import {generateProductId, parseProductId} from "./product-id";
import {CartItem} from "../components/cart/cart.module";
import {resolveValues} from "./rules";
import {PricingRules} from "../../config/pricing";
import {store} from "./redux-store"
import {getPricingConfig} from "../components/pricing/pricing.module";
import {deepClone} from "./object-utils";

export function calculatePrice (options) {
    return calculatePrices(options).price;
}

export function calculateAssemblyPrice (options) {
    return calculatePrices(options).assemblyPrice;
}

export function calculateCartItemPrice (item: CartItem) {
    const {price, assemblyPrice} = calculatePrices(item.product);

    return (price + (item.metadata?.assembled ? assemblyPrice : 0)) * item.quantity;
}


function applyRules (opts, pricingConfig, rules, context = {complete: false}) {
    const cb = () => context.complete = true;
    for (const rule of rules) {
        if (context.complete) {
            break;
        }
        let ruleResult;
        if (typeof rule === 'function') {
            ruleResult = rule(opts, cb, pricingConfig);
        } else if (Array.isArray(rule)) {
            applyRules(opts, pricingConfig, rule, context);
        } else if (rule && typeof rule === 'object') {
            ruleResult = rule;
        } else {
            throw new Error("Invalid product rule: " + rule);
        }

        if (Array.isArray(ruleResult)) {
            applyRules(opts, pricingConfig, ruleResult, context);
        } else if (ruleResult && typeof ruleResult === 'object') {
            Object.assign(opts, ruleResult);
        }
    }
}

export function calculatePrices (options) : {price: number, assemblyPrice: number} | null {
    let productId: string;
    const context: any = {};
    if (typeof options === 'string') {
        productId = options;
        Object.assign(context, parseProductId(options));
    } else {
        Object.assign(context, deepClone(options));
        Object.assign(context, resolveValues(context, context));
        try {
            productId = generateProductId(options);
        } catch (ex) {
            return null;
        }
    }

    context.productId = productId;
    const pricingConfig = getPricingConfig(store.getState());

    const keys = Reflect.ownKeys(context);

    applyRules(context, pricingConfig, PricingRules);

    for (const key of keys) {
        delete context[key];
    }

    if (!Number.isFinite(context.price) || context.assemblyAvailable && !Number.isFinite(context.assemblyPrice)) {
        throw new Error("Price or assembly price could not be generated")
    }

    return context;
}
