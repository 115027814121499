import * as React from 'react';
import {findProductFromCatalog, useCatalog} from "../network/catalog";
import {ErrorView} from "./error-view";
import {Product, ProductAttribute, ProductSelectAttribute, ProductTextAttribute} from "../interfaces/catalog";
import {ProductImageGallery} from "./product-view/product-image-gallery";
import {ProductInfo} from "./product-view/product-info";
import {resolveValues} from "../util/rules";
import {ProductTable} from "./product-view/product-table";
import {getPersistentStore, PersistentStore} from "../util/persistent-store";
import {Breakpoint, getBreakpointDescription, getCurrentBreakpoint} from "../util/breakpoint";
import {useLocation} from "react-router";
import {ProductButtons} from "./product-view/product-buttons";
import {calculatePrices} from "../util/price-utils";
import {generateProductId, parseProductId} from "../util/product-id";
import {parseQueryString} from "../util/query-string";
import {usePricingConfig} from "../network/pricing";
import {useCallback, useEffect, useState} from "react";
import {ValidationState} from "../util/validate";
import {shallowEqual} from "react-redux";
import {Spinner} from "../components/spinner";

enum State {
    Loading,
    Loaded,
    Error
}

interface ProductViewProps {
    productId: string;
}

interface ProductViewState {
    state: State;
    product?: Product;
    error?: Error;
    productId: string;
    selectedAttributes: { [key: string]: any };
}

function findDefaultTextValue (attribute: ProductTextAttribute) {
    return attribute.defaultValue;
}

function findDefaultSelectValue (attribute: ProductSelectAttribute, persistedValue: any) {
    if (!attribute.options) {
        //We're not a select attribute after all
        //We don't have enough information to determine what the persisted value should be
        //So just return it.
        return persistedValue;
    }

    if (persistedValue !== undefined) {
        //There was a persisted value -- make sure that it still exists in the options we have now
        //since it's possible the catalog will be updated.
        if (attribute.options.find(option => {
            const optionValue = option && option.value !== undefined ? option.value : option;
            return persistedValue === optionValue;
        })) {
            //We found the persisted value
            return persistedValue;
        }
    }

    const defaultOption = attribute.options.find(value => value.default);
    return defaultOption ? defaultOption.value : null;
}

function getContext (item) {
    const context = {...item};
    return item.$ctx ? {...item, ...resolveValues({$ctx: item.$ctx}, context)} : context;
}

function findDefaultAttributes (product: Product) {
    const context = getContext(product);
    const result = {};
    if (Array.isArray(product.attributes)) {
        for (let each of product.attributes) {
            each = resolveValues(each, context);
            if (each?.name) {
                const selectedValue = typeof product[each.name] === 'undefined' ? getPersistentStore(PersistentStore.Attributes).get(each.name) : product[each.name];
                switch (each.type) {
                    case 'text':
                        result[each.name] = typeof selectedValue !== 'undefined' ? selectedValue : findDefaultTextValue(each);
                        break;
                    default:
                        result[each.name] = findDefaultSelectValue(each as ProductSelectAttribute, selectedValue);
                        break;
                }
                Object.assign(context, result)
            }
        }
    }
    return result;
}

function addProductAttributes (product: any) {
    const context = getContext(product);
    const resolved = resolveValues(product, context);
    Object.assign(resolved, context);
    let productId = resolveValues(product.productId, resolved);

    if (typeof productId === 'string') {
        try {
            Object.assign(resolved, parseProductId(productId));
        } catch (err) {}
    }
    try {
        console.log({...resolved})
        productId = generateProductId(resolved);
    } catch (err) {
        productId = null;
    }

    product.productId = productId;

    if (productId) {
        console.log("Parsing product id: ", productId);
        Object.assign(product, parseProductId(productId));
    }

    Object.assign(product, calculatePrices(product));
}


function getSelectedAttributesFromQueryString (queryString: string) {
    const from = parseQueryString(queryString).from;
    return from ? { productId: from, ...parseProductId(from)} : {};
}

const Loading = () => <div className='product'>
    <div className="product-info">
        <Spinner loadingText={'Loading products...'} />
    </div>
</div>;

export const ProductView: React.FC<ProductViewProps> = (props) => {
    const {productId} = props;
    const location = useLocation();
    const catalog = useCatalog();
    const pricingConfig = usePricingConfig();
    const [validationState, setValidationState] = useState<Record<string, ValidationState>>({});
    // const [errors, setErrors] = useState<Record<string, string[]>>({});
    // const setAttributeErrors = React.useCallback((attributeName: string, attributeErrors: string[]) => {
    //     if (!errors[attributeName] || (errors[attributeName].join('') !== attributeErrors.join(''))) {
    //         setErrors({
    //             ...errors,
    //             [attributeName]: attributeErrors
    //         })
    //     }
    // }, [errors]);

    const [selectedAttributes, setSelectedAttributes] = useState(getSelectedAttributesFromQueryString(location.search));

    const handleAttributeUpdate = React.useCallback((attribute: ProductAttribute, value: any, attributeState: ValidationState) => {
        if (selectedAttributes[attribute.name] === value) {
            return;
        }

        setValidationState({
            ...validationState,
            [attribute.name]: attributeState
        });

        setSelectedAttributes(
            {
                ...selectedAttributes,
                [attribute.name]: value
            }
        );

        if (attribute.persist) {
            getPersistentStore(PersistentStore.Attributes).set(attribute.name, value);
        }
    }, [selectedAttributes, validationState]);

    const shouldAddToCart = useCallback(() => {
        for (const each in validationState) {
            if (validationState[each] === ValidationState.Invalid) {
                return false;
            }
        }
        return true;
    }, [validationState]);

    useEffect(() => {
        const newSelectedAttributes = getSelectedAttributesFromQueryString(location.search);
        if (!shallowEqual(selectedAttributes, newSelectedAttributes)) {
            setSelectedAttributes(newSelectedAttributes);
        }
    }, [productId]);

    if (!(catalog && pricingConfig)) {
        return <Loading/>;
    }

    const unresolvedProduct = findProductFromCatalog(catalog, productId);

    if (!unresolvedProduct) {
        return <ErrorView code={500} error={new Error("Product does not exist: " + productId)}/>;
    }

    try {
        const attributesWithDefaults = findDefaultAttributes({...unresolvedProduct, ...selectedAttributes});
        const unresolvedProductWithAttributes: any = {...unresolvedProduct, ...attributesWithDefaults};
        addProductAttributes(unresolvedProductWithAttributes);
        Object.assign(unresolvedProductWithAttributes, attributesWithDefaults);
        const context = getContext(unresolvedProductWithAttributes);
        const product: Product = resolveValues(unresolvedProductWithAttributes, context);
        Object.assign(product, resolveValues(context, context));
        const isSingleColumn = getCurrentBreakpoint().maxWidth < getBreakpointDescription(Breakpoint.Large).maxWidth;
        const title = <div className='product-title'>{product.title}</div>;
        const productInfo = <ProductInfo
            product={product}
            onAttributeUpdate={handleAttributeUpdate}
        />;
        const gallery = <ProductImageGallery images={product.images}/>;
        const productTable = <ProductTable product={product} context={context}/>;

        const cartButtons = <ProductButtons
            product={product}
            validator={shouldAddToCart}
        />;

        return <div className="product">
            <div className="product-info">
                {isSingleColumn
                    ? <React.Fragment>
                        {title}
                        {gallery}
                        {productInfo}
                        {productTable}
                        {cartButtons}
                    </React.Fragment>
                    : <React.Fragment>
                        <div className={'left-column'}>
                            {gallery}
                            {productTable}
                        </div>
                        <div className={'right-column'}>
                            {title}
                            {productInfo}
                            {cartButtons}
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>

        // return <FormContext.Provider value={form}>
         
        // </FormContext.Provider>;

    } catch (err) {
        console.log("Error occurred while rendering: ", err);
    }

};