import * as React from "react";
import {PropsWithChildren, useEffect, useState} from "react";
import {BrowserRouter, Route, RouteProps, Switch, withRouter} from 'react-router-dom';
import {LandingView} from "./views/landing-view";
import {ContactView} from "./views/contact-view";
import {ErrorView} from "./views/error-view";
import {Tab} from "./components/navbar";

import * as urls from './util/url';
import {Categories} from "./util/categories";
import {Services} from "./util/services";
import {CatalogView} from "./views/catalog-view";
import {ServicesView} from "./views/services-view";
import {ProductsView} from "./views/Products-view";
import {AppFooter} from "./components/footer";
import {FaqView} from "./views/faq-view";
import {Products} from "./util/products";
import {Resources} from "./views/resources";
import {AboutUs} from "./views/aboutus-view";
import {addBreakpointListener, Breakpoint, getCurrentBreakpoint, removeBreakpointListener} from "./util/breakpoint";
import {Provider} from "react-redux";
import {store} from "./util/redux-store";
import {CartView} from "./views/cart-view";
import {Toaster} from "./components/toast/toast.component";
import {OrderCompletedView} from "./views/order-completed";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {AppHeader} from "./components/header";

const tabs: Array<Tab> = [
    {
        displayName: 'Products',
        path: urls.getProductsUrl(),
        children: [
            ...Products.map(product => (
                {
                    displayName: product.displayName,
                    path: urls.getProductUrl(product.name)
                }
            )),
            {
                displayName: "Catalog",
                path: urls.getCatalogUrl()
            }
        ]
    },

    {
        displayName: 'Catalog',
        path: urls.getCatalogUrl(),
        children: [
            {
                displayName: 'View All Products',
                path: urls.getCatalogUrl(),
                divider: true
            },
            ...Categories.map(category => (
                {
                    displayName: category.displayName,
                    path: urls.getCatalogCategoryUrl(category.name)
                }
            ))
        ]
    },

    {
        displayName: 'Services',
        path: urls.getServicesUrl(),
        children: Services.map(service => (
            {
                displayName: service.displayName,
                path: urls.getServiceUrl(service.name)
            }
        )),
        collapse: Breakpoint.Small
    },

    {
        displayName: "FAQs",
        path: urls.getFAQsUrl(),
        collapse: Breakpoint.Small
    },

    {
        displayName: "About Us",
        path: urls.getAboutUsUrl(),
        collapse: Breakpoint.Large
    },

    {
        displayName: "Contact",
        path: urls.getContactUrl()
    },

    {
        displayName: "Resources",
        path: urls.getResourcesUrl(),
        collapse: Breakpoint.Large
    }
];

const Scroller = withRouter((props: PropsWithChildren<RouteProps>) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [props.location.pathname]);

    return null;
});

const AppRouter = () => <Switch>
    <Route path={[urls.getHomeUrl(), "/index.html", '/home', "/"]} exact component={LandingView} />
    <Route path={[
        urls.getCatalogCategoryUrl(':categoryId'),
        urls.getCatalogProductUrl(':productId'),
        urls.getCatalogUrl()
    ]} exact component={CatalogView} />
    <Route path={[urls.getProductUrl(':productsName'), urls.getProductsUrl()]} exact component={ProductsView} />
    <Route path={[urls.getServiceUrl(':serviceName'), urls.getServicesUrl()]} exact component={ServicesView} />
    <Route path={urls.getCartUrl()} exact>
        <CartView />
    </Route>
    <Route path={urls.getOrderCompletedUrl()} exact>
        <OrderCompletedView />
    </Route>
    <Route path={urls.getFAQsUrl()} exact>
        <FaqView />
    </Route>
    <Route path={urls.getContactUrl()} exact>
        <ContactView />
    </Route>
    <Route path={urls.getAboutUsUrl()} exact>
        <AboutUs />
    </Route>
    <Route path={urls.getResourcesUrl()} exact>
        <Resources />
    </Route>
    <Route>
        <ErrorView code={404} />
    </Route>
</Switch>;

export const App = () => {
    const [breakpoint, setBreakpoint] = useState(getCurrentBreakpoint);

    useEffect(() => {
        const onBreakpointChanged = () => setBreakpoint(getCurrentBreakpoint());

        addBreakpointListener(onBreakpointChanged);

        return () => {
            removeBreakpointListener(onBreakpointChanged);
        }
    }, []);

    return <Provider store={store}>
        <GoogleReCaptchaProvider reCaptchaKey='6LcTOcIUAAAAADp0-A8-bpD50fJWEh9_41JzXXlU'>
            <Toaster />
            <BrowserRouter>
                <Scroller />
                <Switch>
                    <Route>
                        <div className={'app-wrapper ' + (breakpoint ? breakpoint.className : "")}>
                            <AppHeader tabs={tabs} />
                            <div className="app-content">
                                <AppRouter />
                            </div>
                            <AppFooter/>
                        </div>
                    </Route>
                </Switch>
            </BrowserRouter>
        </GoogleReCaptchaProvider>
    </Provider>;
};