import {CatalogCategory} from "./catalog";

export enum Category {
    Door = "DR",
    Window = "WN",
    Kit = "KIT"
}

export interface CategoryDescription {
    displayName: string;
    name: CatalogCategory;
    description: string
}

