import * as React from "react";
import {GoogleReCaptcha} from 'react-google-recaptcha-v3';
import {showCaptcha} from "../util/captcha";

export const Captcha: React.FC<{onVerify: (code:string) => any, onError: (err) => any}> = (props) => {
    React.useEffect(() => {
        showCaptcha(5000)
    });

    return <GoogleReCaptcha onVerify={props.onVerify} />;
};

export const CaptchaTOS: React.FC<{}> = () => {
    return <div className="captcha-tos">
        This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </div>;
};