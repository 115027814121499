import * as React from "react";
import {getImageUrl, getResourcesUrl, getResourcesImageUrl} from "../util/url";

export class Resources extends React.Component {
    render () {
        return <div className={'resources-view'}>
            <div className='section-header'>Resources</div>
            <div>
		<a href={getResourcesUrl('anatomy_of_a_door.pdf')}>
                    <div className={'resource-item'}>
                        <div className={'resource-image center'}>
                            <img src={getResourcesImageUrl('anatomy_of_a_door.jpg')} />
                        </div>
                        <div className={'resource-content'}>
                            Anatomy of a Door (pdf, 335KB)
                        </div>
                    </div>
                </a>
            </div>

            <br />
            <br />

            <div>
                <a href={getResourcesUrl('general_assembly_instructions.pdf')}>
                    <div className={'resource-item'}>
                        <div className={'resource-image center'}>
                            <img src={getResourcesImageUrl('general_assembly_instructions_th.jpg')} />
                        </div>
                        <div className={'resource-content'}>
                            General Kit Assembly Instructions (pdf, 151KB)
                        </div>
                    </div>
                </a>
            </div>

            <br />
            <br />

            <div>
                <a href={getResourcesUrl('door_kit_assembly_instructions.pdf')}>
                    <div className={'resource-item'}>
                        <div className={'resource-image center'}>
                            <img src={getResourcesImageUrl('door_kit_assembly_instructions_th.jpg')} />
                        </div>
                        <div className={'resource-content'}>
                            Door Kit Assembly Instructions (pdf, 232KB)
                        </div>
                    </div>
                </a>
            </div>

            <br />
            <br />

            <div>
                <a href={getResourcesUrl('window_kit_assembly_instructions.pdf')}>
                    <div className={'resource-item'}>
                        <div className={'resource-image center'}>
                            <img src={getResourcesImageUrl('window_kit_assembly_instructions_th.jpg')} />
                        </div>
                        <div className={'resource-content'}>
                            Window Kit Assembly Instructions (pdf, 183KB)
                        </div>
                    </div>
                </a>
            </div>

            <br />
            <br />

            <div>
                <a href={getResourcesUrl('KIT0001_assembly_instructions.pdf')}>
                    <div className={'resource-item'}>
                        <div className={'resource-image center'}>
                            <img src={getResourcesImageUrl('KIT0001_assembly_instructions_th.jpg')} />
                        </div>
                        <div className={'resource-content'}>
                            KIT0001 (Whistle Stop Platform) Assembly Instructions (pdf, 1.2MB)
                        </div>
                    </div>
                </a>
            </div>

            <br />
            <br />

            <div>
                <a href={getResourcesUrl('KIT0001_roofing_template-24.pdf')}>
                    <div className={'resource-item'}>
                        <div className={'resource-image center'}>
                            <img src={getResourcesImageUrl('KIT0001_roofing_template-24_th.jpg')} />
                        </div>
                        <div className={'resource-content'}>
                            KIT0001 (Whistle Stop Platform) Roofing Template 1:24 (pdf, 13KB)
                        </div>
                    </div>
                </a>
            </div>

            <br />
            <br />

            <div>
                <a href={getResourcesUrl('KIT0001_roofing_template-20.pdf')}>
                    <div className={'resource-item'}>
                        <div className={'resource-image center'}>
                            <img src={getResourcesImageUrl('KIT0001_roofing_template-20_th.jpg')} />
                        </div>
                        <div className={'resource-content'}>
                            KIT0001 (Whistle Stop Platform) Roofing Template 1:20 (pdf, 13KB)
                        </div>
                    </div>
                </a>
            </div>

            <br />
            <br />

            <div>
                <a href={getResourcesUrl('NGRC2019_clinic_Laser_Cutter_as_Modeling_Tool.pdf')}>
                    <div className={'resource-item'}>
                        <div className={'resource-image'}>
                            <img src={getResourcesImageUrl('laser-cutting-as-modeling-tool_th.jpg')} />
                        </div>
                        <div className={'resource-content'}>
                            The Laser Cutter as a Modeling Tool (pdf, 825KB)
                        </div>
                    </div>
                </a>
            </div>

        </div>;
    }
}
