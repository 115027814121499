import React from 'react';
import {connect} from "react-redux";
import {getToasts, ToastItem} from "./toast.module";
import {Toast} from 'react-bootstrap';
import {hideMessage} from "./toast.module";

interface ToasterProps {
}

interface ConnectedToasterProps extends ToasterProps {
    toasts: Array<ToastItem>;
    hideToast: (toast: ToastItem) => any;
}

class ConnectedToaster extends React.Component<ConnectedToasterProps, any> {
    render() {
        const {toasts, hideToast} = this.props;

        return toasts?.length
            ? <div className={'toast-container'}>
                {toasts.map(toast => <Toast
                        onClose={() => {
                            hideToast(toast);
                        }}
                        show={true}
                        className={toast.className}
                        delay={toast.duration > 0 ? toast.duration : undefined}
                        autohide={toast.duration > 0}
                    >
                        <Toast.Header>{toast.header}</Toast.Header>
                        <Toast.Body>{toast.body}</Toast.Body>
                    </Toast>
                )}
            </div>
            : null
    }
}

const mapStateToProps = (state) => ({
    toasts: getToasts(state)
});

const mapDispatchToProps = (dispatch) => ({
    hideToast: (toast: ToastItem) => dispatch(hideMessage(toast))
});

export const Toaster = connect(mapStateToProps, mapDispatchToProps)(ConnectedToaster) as any as React.ComponentClass<ToasterProps>;