import React from 'react';
import {connect} from 'react-redux';

interface OrderCompletedProps {
}

interface ConnectedOrderCompletedProps extends OrderCompletedProps {
}

class ConnectedOrderCompletedView extends React.Component<ConnectedOrderCompletedProps, any> {

    constructor(props) {
        super(props);
    }

    render () {
        return <div className={'submit-order'}>
            Your order has been received successfully and we'll be in contact with you soon!
        </div>
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export const OrderCompletedView = connect(mapStateToProps, mapDispatchToProps)(ConnectedOrderCompletedView) as any as React.ComponentClass<OrderCompletedProps>;