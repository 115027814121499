import {ProductAttribute} from "../interfaces/catalog";

export enum ValidationState {
    Valid,
    Invalid
}

export function validate(attribute: ProductAttribute, value: any) {
    if (attribute.required && (value === undefined || value === null || value === '')) {
        return [`${attribute.label || attribute.name} is required.`];
    }

    const validations = attribute.validations;
    const errors = [];

    if (validations) {
        for (const validation of validations) {
            if (validation.applies) {
                const applies = Array.isArray(validation.applies)
                    ? validation.applies
                    : [validation.applies];
                if (applies.find(each => !(new RegExp(each).test(value ?? "")))) {
                    continue;
                }
            }

            let found = !!(validation.patterns.find(
                pattern => !(new RegExp(pattern).test(value ?? ""))
            ));

            if (validation.invert) {
                found = !found;
            }

            if (found) {
                errors.push(validation.message);
            }
        }
    }

    return errors;
}