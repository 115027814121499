import * as React from "react";
import {getImageUrl} from "../util/url";

export class AboutUs extends React.Component {
    render () {
        return <div className={'aboutus-view'}>
            <div className='section-header'>About Us</div>
            <div className='section-content'>
		<p>
		    First and foremost, I am an engineer, a woodworker, and a model railroader. Originally modeling in HOn3, I now create railroads in the garden, modeling in Fn3 (1:20.3 scale). And I love making wooden structures and details.
		</p>
		<div>
			<img src={getImageUrl('garden_railroad.jpg')} className={'splash-image'} />
			<p>
				Working in 1:20 scale, I quickly became aware of how few parts and details were available in that scale, most being available in 1:24 scale, and some 1:22.5 scale. There was a very limited selection of 1:20 doors in molded plastic to be had, but no 1:20 windows at all. So for my buildings I had to either make do with the available 1:24 windows which simply didn't look right in my 1:20 world, or stick-build each window individually which required a major time commitment for each windows.  There HAD to be a better way.
			</p>

			<p>
				About that same time, I wanted to build some horse-drawn wagons for my circa 1890 railroad, and discovered that there were no decent wagon wheels available at all, and those aren't easy to scratch build, so I went looking for a solution for myself.
			</p>
		</div>
		<p>
		    I discovered the CO2 Laser Cutter. "Ready to use" cutters were prohibitively expensive, but I found I could get an affordable small imported laser cutter <i>if</i> I was willing to do some subtantial rebuilding and upgrades. Being an engineer by training, and having extensive experience with CAD designs, this was a challenging but doable project for me. I bought the laser cutter, spent three months rebuilding it to make it a proper tool, and several more month learning how to program the machine and make it work for me. Soon I was making doors, windows, wagon wheels, and other details and parts at <em>MY</em> scale and exactly as <em>I</em> wanted them to be.
		</p>
		<p>
		    Eventually I outgrew my original small laser cutter and decided to upgrade to one with a larger bed (700mm x 500mm) and higher power (60W). Again I chose to go the import-and-rebuild-it route, leveraging what I had learned the first time around. The result has been very satisfactory, resulting in a wonderful tool.
		</p>
		<p>
		    As a member of our local Garden Railroading club, I became aware that most of the other club members shared similar frustrations of having to make do with a limited number of existing choices, often them not even being in the correct scale. So I started offering my laser-cut doors and windows in both 1:20 and 1:24 to them for their builds&hellip; and they all loved them. I also started to do custom structure wall panels with laser-etched siding and door/window openings, which has proved very popular.
		</p>
		<p>
		    Our entire product line is a direct answer to our own modeling needs, at the high standards that we ourselves demand. Get the doors and windows that you really want, created by a modeler that understands your needs. 
		</p>
		<br />
		<br />
		<p>
		    <i>&mdash; Joe Eckardt, CEO/Chief Engineer</i>
		</p>
            </div>
        </div>
    }
}
