import * as React from "react";
import {ChangeEvent, useState} from "react";
import {EmailType, ProductInquiryPayload, sendMessage} from "../util/email";
import {CaptchaTOS, Captcha} from "./captcha";
import {store} from "../util/redux-store";
import {showMessage} from "./toast/toast.module";

export enum Field {
    Name = "name",
    Email = "email",
    Message = "message",
    Phone = "phone",
    ZipCode = "zipCode"
}

interface ContactFormProps {
    fields?: Array<Field>;
    messageType?: EmailType;
    data?: any;
    submitButtonText?: string;
    submitButtonSubmittedText?: string;
    submitButtonSendingText?: string;
    submitErrorText?: string;
    onSubmit?: () => any;
}

function required (value, message = "This field is required") {
    if (typeof value === 'undefined' || (value.trim && !value.trim())) {
        throw new Error(message);
    }
}

function range (value: number, min: number, max: number, message) {
    value = Number(value);
    if (value < min || value > max) {
        throw new Error(message);
    }
}

function maxLength (value, length: number, message) {
    range(value.length, 0, length, message);
}

function pattern (value: string, pattern: RegExp, message: string) {
    if (!pattern.test(value)) {
        throw new Error(message)
    }
}

function validateField (field: Field, form) {
    const value = form[field];

    switch (field) {
        case Field.Email:
            required(value);
            if (value.length > 200 || !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
                throw new Error("Invalid email address provided.");
            }
            break;
        case Field.Message:
            required(value);
            maxLength(value, 2000, "Message cannot exceed 2000 characters.");
            break;
        case Field.Name:
            required(value);
            maxLength(value, 50, "Name cannot exceed 50 characters.");
            break;
        case Field.Phone:
            required(value);
            range(value.length, 10, 20,"Please enter a valid phone number.");
            break;
        case Field.ZipCode:
            required(value);
            pattern(value, /^[0-9]{5}(-?[0-9]{4})?$/,"Please enter a valid US zip code.");
            break;
    }
}

function validate (form: ProductInquiryPayload, fields: Array<Field>) {
    const errors = {};

    for (const field of fields) {
        try {
            validateField(field, form);
        } catch (err) {
            errors[field] = err.message;
        }
    }

    return errors;
}

const ErrorMessage: React.FC<{message: string | null}> = (props) => {
    if (!props.message) {
        return null;
    }

    return <span className={'error-message'}>
        {props.message}
    </span>
};

export const ContactForm: React.FC<ContactFormProps> = (props) => {
    const {
        fields = [Field.Name, Field.Email, Field.Message] as Array<Field>,
        data = {},
        messageType = EmailType.ProductInquiry,
        submitButtonSendingText = "Sending...",
        submitButtonSubmittedText = "Message sent successfully!",
        submitButtonText = "Send Message",
        submitErrorText = "Your message could not be sent. Please try again."
    } = props;

    const [state, setState] = useState({
        errors: {},
        form: {} as ProductInquiryPayload,
        submitError: null,
        submitted: false,
        submitting: false,
        verifying: false
    });

    const {
        submitError,
        submitted,
        submitting,
        verifying,
        form,
        errors
    } = state;

    const handleError = err => {
        store.dispatch(showMessage({
            header: "Something went wrong",
            body: submitErrorText,
            className: "error"
        }));

        setState({
            ...state,
            verifying: false,
            submitting: false,
            submitted: false,
            submitError: err
        });
    };

    const handleVerification = (code) => {
        sendMessage(messageType as any, code, {...form, ...data}).then(() => {
            setState({
                ...state,
                verifying: false,
                submitting: false,
                submitted: true
            });
            props.onSubmit?.();
        }).catch(handleError);
    };

    const handleButtonClick = () => {
        const errors = validate(form, fields);
        if (Reflect.ownKeys(errors).length) {
            return setState({
                ...state,
                errors
            });
        }
        setState({...state, verifying: true, submitError: null});
    };

    const createUpdateHandler = (field: string, form) => (ev: ChangeEvent) => {
        form[field] = (ev.target as HTMLInputElement).value;
        if (errors[field]) {
            setState({
                ...state,
                errors: {
                    ...errors,
                    [field]: null
                }
            });
        }
    };

    let button;

    if (submitted) {
        button = <button className={'success'} disabled>{submitButtonSubmittedText}</button>
    } else if (submitting || verifying) {
        button = <button disabled>{submitButtonSendingText}</button>
    } else {
        button = <button onClick={handleButtonClick}>{submitButtonText}</button>;
    }

    return <div className={'contact-form'}>
        {fields.map(field => {
            switch (field) {
                case Field.Phone:
                    return <React.Fragment key={field}>
                        <label htmlFor="contact-phone">Contact Phone Number (required)</label>
                        <ErrorMessage message={errors[Field.Phone]}/>
                        <input
                            className={errors[Field.Phone] && "invalid"}
                            type="tel"
                            id="contact-phone"
                            name="contact-phone"
                            placeholder="Your contact phone number... "
                            onChange={createUpdateHandler(Field.Phone, form)}
                        />
                    </React.Fragment>;
                case Field.Email:
                    return <React.Fragment key={field}>
                        <label htmlFor="contact-email">Your Email (required)</label>
                        <ErrorMessage message={errors[Field.Email]}/>
                        <input
                            className={errors[Field.Email] && "invalid"}
                            type="email"
                            id="contact-email"
                            name="contact-email"
                            placeholder="Your email... "
                            onChange={createUpdateHandler(Field.Email, form)}
                        />
                    </React.Fragment>;
                case Field.Name:
                    return <React.Fragment key={field}>
                        <label htmlFor="contact-name">Your Name</label>
                        <ErrorMessage message={errors[Field.Name]}/>
                        <input
                            className={errors[Field.Name] && "invalid"}
                            type="text"
                            id="contact-name"
                            name="contact-name"
                            placeholder="Your name..."
                            onChange={createUpdateHandler(Field.Name, form)}
                        />
                    </React.Fragment>;
                case Field.Message:
                    return <React.Fragment>
                        <label htmlFor="contact-message">Message (required)</label>
                        <ErrorMessage message={errors[Field.Message]} />
                        <textarea
                            className={errors[Field.Message] && "invalid"}
                            id="contact-message"
                            placeholder="Enter a message..."
                            onChange={createUpdateHandler(Field.Message, form)}
                        />
                    </React.Fragment>;
                case Field.ZipCode:
                    return <React.Fragment key={field}>
                        <label htmlFor="contact-zipcode">Zip Code (required to estimate shipping and taxes)</label>
                        <ErrorMessage message={errors[Field.ZipCode]}/>
                        <input
                            className={errors[Field.ZipCode] && "invalid"}
                            type="text"
                            id="contact-zipcode"
                            name="contact-zipcode"
                            placeholder="Zip Code..."
                            onChange={createUpdateHandler(Field.ZipCode, form)}
                        />
                    </React.Fragment>;
            }
        })}

        {
            verifying &&
            <Captcha onVerify={handleVerification} onError={handleError} />
        }

        { submitError && <ErrorMessage message={submitError.message}/>}
        { button }
        <CaptchaTOS/>

    </div>;
};
