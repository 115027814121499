import React from 'react';
import { tagHandler } from "./taglib-utils";
type RootElement = string | React.FC | React.ComponentClass;

interface ClosingTag {
    tag: string;
    index: number;
    endIndex: number;
}

interface OpeningTag extends  ClosingTag {
    options: any;
}

export type TagHandler = (tag: string, content: string, options: any) => string;

const ParserPattern = /\[(\/)?([a-z_0-9]+)(?:\|([^\[\]]+?))?]/g;
export function parseTags (toProcess: string) {
    const openings: Array<OpeningTag> = [];
    const closings: Array<ClosingTag> = [];
    let match;
    while (match = ParserPattern.exec(toProcess)) {
        const [fullMatch, isClose, tag, options] = match;
        const {index} = match;
        const endIndex = index + fullMatch.length;
        const toAdd = {
            tag: tag.toUpperCase(),
            index,
            endIndex
        };

        if (isClose) {
            closings.push(toAdd)
        } else {
            const tagOptions = {};
            if (options) {
                for (const option of options.split("|")) {
                    const [key, value] = option.split("=");
                    tagOptions[key] = value;
                }
            }
            (toAdd as OpeningTag).options = tagOptions;
            toAdd.endIndex -= 1;
            openings.push(toAdd as OpeningTag);
        }
    }

    return {openings, closings};
}

export function findClosingTag (openings: Array<OpeningTag>, closings: Array<ClosingTag>, openingIndex: number) {
    let openingTag = openings[openingIndex];
    let depth = 1;
    let closingIndex;
    for (let i = 0; i < closings.length; i++) {
        const closingTag = closings[i];
        if (closingTag.index > openingTag.index && openingTag.tag === closingTag.tag) {
            closingIndex = i;
            depth -= 1;
            for (let j = openingIndex + 1; j < openings.length; j++) {
                let eachOpening = openings[j];
                if (eachOpening.index > closingTag.index) {
                    break;
                }
                if (eachOpening.tag === openingTag.tag) {
                    depth += 1;
                    openingIndex = j;
                }
            }
        }

        if (!depth) {
            return closingIndex;
        }
    }

    return null;
}

export class TagProcessor {

    constructor (public handleTag: TagHandler = tagHandler) {

    }
    
    protected sanitize (toSanitize: string = "") {
        return toSanitize
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&rt;');
    }

    protected handleTagStack (toProcess: string, openings: Array<OpeningTag>, closings: Array<ClosingTag>, startIndex, endIndex) {
        let lastIndex = startIndex;
        let result = '';
        for (let i = 0; i < openings.length; i++) {
            const openingTag = openings[i];
            const closingIndex = findClosingTag(openings, closings, i);
            if (closingIndex === null) {
                throw new Error(`Found opening tag [${openingTag.tag}] with no corresponding closing tag at position ${openingTag.index} of ${toProcess}`);
            }
            const closingTag = closings[closingIndex];
            const innerTags = [];
            for (let j = i + 1; j < openings.length; j++) {
                if (openings[j].index < closingTag.index) {
                    innerTags.push(openings[j]);
                } else {
                    break;
                }
            }

            result += toProcess.substring(lastIndex, openingTag.index); //Left side.;
            let stackResult;
            if (innerTags.length) {
                stackResult = this.handleTagStack(toProcess, innerTags, closings, openingTag.endIndex + 1, closingTag.index);
            } else {
                stackResult = toProcess.substring(openingTag.endIndex + 1, closingTag.index);
            }

            result += this.handleTag(openingTag.tag, stackResult, openingTag.options);
            lastIndex = closingTag.endIndex;
            i += innerTags.length;
        }

        return result + toProcess.substring(lastIndex, endIndex); //Add remaining result
    }

    public process (toProcess: string) {
        toProcess = this.sanitize(toProcess);
        const {openings, closings} = parseTags(toProcess);
        let processed: string;
        if (openings.length) {
            processed = this.handleTagStack(toProcess, openings, closings, 0, toProcess.length);
        } else {
            processed = toProcess;
        }

        return {
            processed,
            tags: openings.length
        };
    }
}

export function processTags (toProcess: string, rootElement: RootElement = "span", tagHandler?: TagHandler) {
    const {processed: html, tags} = new TagProcessor(tagHandler).process(toProcess);
    return tags ? React.createElement(rootElement, { dangerouslySetInnerHTML: { __html: html}} as any) : toProcess;
}