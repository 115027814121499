import {Conditional, ProductRule, Switch} from "../interfaces/catalog";
import {clone} from './object-utils';
import * as extensions from './rule-extensions';

const SimplePlaceholderPattern = /^[a-z_$][a-z0-9_$]*$/i;
const PlaceholderPattern = /{([a-z_$][a-z0-9_$]*)}/ig;

function execWithContext (input, context, debug = false) {
    if (!input || typeof input !== 'string') {
        return input;
    }
    try {
        context = {...extensions, ...context}; //Shallow clone

        if (debug) {
            console.log("[Exec] " + input, clone(context));
        }

        let fn: any = input.replace(PlaceholderPattern, (match, placeholderName) => {
            if (!Reflect.has(context, placeholderName)) {
                context[placeholderName] = undefined;
            }
            return placeholderName;
        });

        const argNames = Reflect.ownKeys(context) as Array<string>;
        fn = new Function(...argNames, `return ${fn}`);
        const result = fn.apply(context, argNames.map(argName => context[argName]));

        if (debug) {
            console.log("[Exec Result]", result);
        }

        return result;
    } catch (err) {
        console.error("[Exec error] " + err.message, input, context);
    }
    return "";
}

function matches (query, context) {
    for (const key of Reflect.ownKeys(query)) {
        if (query[key] === null || query[key] === undefined) {
            return context[key] === null || context[key] === undefined;
        }else if (query[key] !== context[key]) {
            return false;
        }
    }

    return true;
}

type RuleOrType <T> = T | ProductRule<T>;

export function replacePlaceholders (str: string, context, debug?, sanitizeOutput = false) {
    if (debug) {
        console.log("[replacePlaceholders] " + str );
    }

    let result = "";
    let temp = "";
    let count = 0;
    let escaped = false;
    for (let i = 0; i < str.length; i++) {
        const char = str.charAt(i);
        if (escaped) {
            temp += char;
            escaped = false;
        } else if (char === "{") {
            if (!count) {
                result += temp;
                temp = "";
            } else {
                temp += char;
            }
            count++;
        } else if (char === "}") {
            if (--count === 0) {
                let execResult = SimplePlaceholderPattern.test(temp) ? context[temp] : execWithContext(replacePlaceholders(temp, context, debug, true), context, debug);
                const isFullLength = temp.length === (str.length - 2);
                temp = "";

                if (sanitizeOutput && typeof execResult === 'string') {
                    execResult = `"${execResult.replace('"', '\\"')}"`;
                }

                if (isFullLength) {
                    //Full length pattern. Don't concatenate -- return the value instead.

                    result = execResult;
                } else {
                    result += execResult;
                }
            } else {
                temp += char;
            }
        } else if (char === '\\') {
            escaped = true;
        } else {
            temp += str.charAt(i);
        }
    }

    if (temp) {
        result += temp;
    }

    if (debug) {
        console.log("[replacePlaceholders [" + str + "]] ", result);
    }

    return result;
}

function resolveConditional <T> (obj: Conditional<T>, context: any, isCtx) {
    let matched = typeof obj.$if === 'string' ? !!replacePlaceholders(`{${obj.$if}}`, context, (obj as any).$debug) : matches(obj.$if, context);
    if (matched) {
        if (obj.$then) {
            return resolve(obj.$then as any, context, isCtx);
        }
    } else if (obj.$else) {
        return resolve(obj.$else as any, context, isCtx);
    }

    return null;
}

function resolveSwitch <T> (obj: Switch<T>, context: any, isCtx) {
    const value = String(replacePlaceholders(`{${obj.$switch}}`, context, (obj as any).$debug));
    const toResolve = obj.$case[value] || obj.$case.default;
    return toResolve ? resolve(toResolve, context, isCtx) : null;
}

function assign (result, toAssign) {
    if (Array.isArray(toAssign)) {
        for (const each of toAssign) {
            assign(result, each);
        }
    } else if (toAssign && typeof toAssign === 'object') {
        Object.assign(result, toAssign);
    }

    return result;
}

function resolve <T> (obj: T | ProductRule<T> | Array<T> | Array<ProductRule<T>>, context = {}, isCtx = false) {
    if (!obj) {
        return obj as T;
    }

    // if (Object.getPrototypeOf(obj) !== Object.prototype) {
    //     return obj;
    // }

    if (obj instanceof RegExp) {
        return obj;
    }

    if ((obj as ProductRule<T>).$raw) {
        return (obj as any).$raw;
    }

    if (Array.isArray(obj)) {
        return (obj as Array<RuleOrType<T>>)
            .map(item => {
                const resolved = resolve(item, context, isCtx);
                if (isCtx) {
                    assign(context, resolved);
                }

                return resolved;
            })
            .filter(item => item !== null) as Array<T>;
    } else if (obj && typeof obj === 'object') {
        obj = obj as ProductRule<T>;

        const isDebug = (obj as any).$debug;
        if (isDebug) {
            console.log("[ResolveValues] Resolving values for: ", obj, clone(context));
        }

        const result = {};

        if ((obj as any).$passthrough) { //Only needed for providing a priority wrapper.
            return resolve((obj as any).$passthrough, context, isCtx);
        }

        if (obj.$ctx) {
            const rules = Array.isArray(obj.$ctx) ? obj.$ctx : [obj.$ctx];
            for (const rule of rules) {
                const resolved = resolve(rule, context, true);
                if (isDebug || (rule as any).$debug) {
                    console.log("[ResolveValues] Rule applied: ", rule, ",\nResolved: ", resolved, ",\ncontext: ", clone(context));
                }
                const toAssign = assign({}, resolved);
                Object.assign(result, toAssign);
                Object.assign(context, toAssign);
            }

            if (isDebug) {
                console.log("[ResolveValues] Context updated: ", clone(context));
            }

        }

        if (typeof obj.$if !== 'undefined') {
            return resolveConditional(obj as Conditional<T>, context, isCtx) as T;
        } else if (typeof obj.$switch !== 'undefined') {
            return resolveSwitch(obj as Switch<T>, context, isCtx) as T;
        }

        const keys = (Reflect.ownKeys(obj) as Array<string>)
            .sort((firstKey, secondKey) => {
                    const first = obj[firstKey]?.$priority || 0;
                    const second = obj[secondKey]?.$priority || 0;

                    if (first > second) {
                        return -1;
                    } else if (first < second) {
                        return 1;
                    }

                    return 0;
                }
            );

        for (const key of keys) {
            if (key.startsWith('$')) {
                continue;
            }
            result[key] = resolve(obj[key], context, isCtx);
        }
        return result as T;
    } else if (typeof obj === 'string') {
        obj = replacePlaceholders(obj, context) as any;
    }

    return obj as T;
}

export function resolveValues <T> (obj: RuleOrType<T>, context?: any) : T;
export function resolveValues <T> (obj: Array<RuleOrType<T>>, context?: any) : Array<T>;
export function resolveValues <T> (obj: T | ProductRule<T> | Array<T> | Array<ProductRule<T>>, context: any = {}) : T | Array<T> | null {
    return resolve(obj, context);
}