import * as React from 'react';
import {getImageUrl} from "../util/url";

interface ServicesViewProps {
    match: {
        params: {
            serviceName?: string
        }
    }
}


interface ServicesViewState {
    serviceName?: string;
}

export class ServicesView extends React.Component<ServicesViewProps, ServicesViewState> {
    constructor(public props: ServicesViewProps) {
        super(props);
    }

    render() {
        const {serviceName} = this.props.match.params;
        if (serviceName == 'laser-cutting') {

            return <div className={'services-view'}>
                <div className='section-header'>Laser-Cutting Services</div>
                <div className='section-content'>
                    <p>
                        We offer a full line of design and laser-cutting services ranging from running your laser-ready
                        design to full-service design from your initial specfications and requirements.
                    </p>
                    <p>
                        We work with a variety of materials including cutting and etching wood, acrylic, paper,
                        cardstock, leather, and other organic materials; additionally we can surface etch stone, glass,
                        and coated metals. (Inquire for specific materials.)
                    </p>
                    <p>
                        Some of the projects we have done include:
                        <ul>
                            <li>Structure wall panels with door/window cut-outs and enscribed paneling</li>
                            <li>Scale model design and manufacture from prototype photos</li>
                            <li>HO scale structure kits</li>
                            <li>Laser-cut "3D Puzzle" kits &ndash; hundreds of designs to choose from</li>
                            <li>Fairy garden accessories</li>
                            <li>Non-modeling related designs and projects</li>
                        </ul>
                    </p>
                </div>
            </div>;
        } else if (serviceName == 'semi-custom') {

            return <div className={'services-view'}>
                <div className='section-header'>Semi-custom Doors, Windows, and Other Items</div>
                <div className='section-content'>
                    <p>
                        Most of our standard products can be modified to fit your needs, including other scales, custom
                        sizes, and compound doors and windows.
                    </p>
                </div>
            </div>;
        } else if (serviceName == 'custom') {

            return <div className={'services-view'}>
                <div className='section-header'>Custom Doors, Windows, and Other Details</div>
                <div className='section-content'>
                    <p>
                        Need something totally different than our standard products? We can design and create just about
                        any customizations that you might need. Please contact us with your requirements for a quote.
                    </p>
                </div>
            </div>;
        } else {
            return <div>{serviceName}</div>;
        }


    }
}
