import {TagHandler} from "../components/tag-processor";

function buildOptionsString (options, keys = Reflect.ownKeys(options)) {
    let result = "";
    for (const key of keys) {
        if (Reflect.has(options, key)) {
            result += ` ${key as string}="${String(options[key]).replace(/"/g, '\\"')}"`;
        }
    }

    return result;
}

function createTagHandlerForOpts (...keys) {
    return (tag, content, options) => {
        return `<${tag}${buildOptionsString(options, keys)}>${content}</${tag}>`;
    };
}

const SimpleTagHandler = createTagHandlerForOpts('style', 'class');

const UrlHandler = (tag, content, options) => {
    options = {
        ...options,
        href: options.href || options.url || content
    };
    return `<a${buildOptionsString(options)}>${content}</a>`;
};

const symbolHandler = (tag, content) => {
    return `&${content};`;
};

const TagLib = {
    a: UrlHandler,
    amp: symbolHandler,
    b: SimpleTagHandler,
    i: SimpleTagHandler,
    link: UrlHandler,
    span: SimpleTagHandler,
    url: UrlHandler,
    sym: symbolHandler
};

export const tagHandler: TagHandler = (tag, content, options) => {
    tag = tag.toLowerCase();
    if (TagLib[tag]) {
        return TagLib[tag](tag, content, options);
    }

    throw new Error(`Unknown tag: [${tag}]`);
};
