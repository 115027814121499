const ApiBasePath = 'https://api.eckardtdesign.com';
const BasePath = '';

export function getContactUrl () {
    return `${BasePath}/contact`;
}

export function getCatalogUrl () {
    return `${BasePath}/catalog`;
}

export function getHomeUrl () {
    return `${BasePath}/`;
}

export function getCatalogCategoryUrl (categoryId: string) {
    return `${getCatalogUrl()}/category/${categoryId}`;
}

// This entry for "products view" of "catalog"
export function getCatalogProductUrl (productId: string) {
   return `${getCatalogUrl()}/product/${productId}`;
}

export function getServicesUrl () {
    return `${BasePath}/services`;
}

export function getServiceUrl (serviceName: string) {
    return `${getServicesUrl()}/${serviceName}`;
}

export function getFAQsUrl () {
    return `${BasePath}/FAQs`;
}

// These next two entries are for "products" pull-down on navbar
export function getProductsUrl () {
    return `${BasePath}/products`;
}

export function getProductUrl (productName: string) {
    return `${getProductsUrl()}/${productName}`;
}
// 

export function getStaticUrl (path) {
    return `/${path}`;
}

export function getImageUrl (image: string) {
    return getStaticUrl(`images/${image}`);
}

export function getResourcesImageUrl (image: string) {
    return getStaticUrl(`images/resources/${image}`);
}

export function getTestimonialsImageUrl (image: string ) {
    return getImageUrl(`testimonials/${image}`);
}

export function getCatalogImageUrl (image: string) {
    return `${getImageUrl('catalog')}/${image}`;
}

export function getCartUrl () {
    return `${BasePath}/cart`;
}

export function getResourcesUrl (resource?: string) {
    if (resource && !resource.startsWith("/")) {
        resource = `/${resource}`;
    }
    return `${BasePath}/resources${resource || ""}`;
}

export function getSendMessageApi () {
    return `${ApiBasePath}/message`;
}

export function getAboutUsUrl () {
    return `${BasePath}/aboutus`;
}

export function getOrderCompletedUrl () {
    return `${BasePath}/order-complete`;
}
