// interface HasContext {
//     $raw?: any;
//     $ctx?: any;
// }
//
// export type PossibleConditional <T> = T | { [key in keyof T] : PossibleConditional<key>} | Switch<T> | Conditional<T>;
// export type ProductRule <T> = ({ [key in keyof T] : ProductRule<key>} | PossibleConditional<T>) & HasContext;
//
// type PossibleConditional <T> = { [key in keyof T]: PossibleConditional<ProductRule<T[key]> | Switch<ProductRule<T[key]>> | Conditional<ProductRule<T[key]>>> }
// type ProductRule <T> = HasContext & PossibleConditional<T & HasContext>;

import {CSSProperties} from "react";

export enum CatalogCategory {
    Windows = 'windows',
    Doors = 'doors',
    WagonWheels = 'wagon-wheels',
    PackingCrates = 'packing-crates',
    Kits = 'kits',
}

export interface ProductAttributeOption {
    label: string;
    value: any;
    default?: boolean;
}

interface ProductAttributeValidation {
    patterns: Array<string>;
    invert?: boolean;
    applies?: string | Array<string>;
    message: string;
}

interface BaseProductAttribute {
    name: string;
    label: string;
    comment?: string;
    placeholder?: string;
    persist?: boolean;
    validations?: Array<ProductAttributeValidation>;
    required?: boolean;
}

export interface ProductSelectAttribute extends BaseProductAttribute {
    type?: "select";
    options: Array<ProductAttributeOption>;
}

export interface ProductTextAttribute extends BaseProductAttribute {
    type: "text"
    defaultValue?: any;
}

export interface ProductTextAreaAttribute extends BaseProductAttribute {
    type: "textarea";
}

export type ProductAttribute = ProductSelectAttribute | ProductTextAttribute | ProductTextAreaAttribute;

export type ProductAttributes = Array<ProductAttribute | ProductRule<ProductAttribute>>

export interface ProductImage {
    url: string | ProductRule<string> ;
    thumbnail?: string | ProductRule<string>;
    caption?: ProductRule<string>;
}

export interface ProductRule <T> extends Partial<Conditional<T>>, Partial<Switch<T>> {
    $raw?: T;
    $ctx?: Array<ProductRule<T> | T>;
}

interface ProductDataTableSection {
    label: string;
    sectionLabelClasses?: Array<string>;
    rows: Array<{
        label: string;
        field?: string;
        labelClasses?: Array<string>;
        valueClasses?: Array<string>;
        value?: any;
        keyStyles?: CSSProperties
        valueStyles?: CSSProperties
    }>;
}

export interface Conditional<T> {
    $if: string | Partial<T>;
    $then?: T;
    $else?: T;
}

export interface Switch<T> {
    $switch: string;
    $case: {
        [key: string]: T
    }
}

interface ProductDataTable {
    classes?: Array<string>;
    labelClasses?: Array<string>;
    valueClasses?: Array<string>;
    styles?: CSSProperties;
    sections: Array<ProductDataTableSection>;
}

export interface Product extends ProductRule<any>{
    id: string;
    title: string;
    description?: string;
    assemblyPrice?: number;
    assembled: boolean;
    assemblyAvailable?: boolean;
    categories: Array<CatalogCategory>;
    attributes?: ProductAttributes;
    dataTable?: ProductDataTable;
    priority?: number;
    options?: any;
    modifiable?: boolean;
    addToCart?: {
        product: string;
        metadata?: any;
    },
    customizeTo?: {
        id: string;
        [key: string]: any;
    };
    images: Array<ProductImage>;
    $price?: {
        [key: string]: {
            price: number;
            assemblyPrice: number;
        }
    }
    price?: number;
}

interface ConcreteProductTemplate extends Partial<Product> {
    template?: boolean;
    parent?: string;
}

export type PossibleConditional <T> = T | {[key in keyof T]: PossibleConditional<T[key]>} | Conditional<PossibleConditional<T>> | Switch<PossibleConditional<T>>;

export type ProductTemplate = PossibleConditional<ConcreteProductTemplate>;
