export const debounce = (fn, millis = 500) => {
    if (!millis) {
        return fn;
    }

    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            fn(...args);
        }, millis);
    };
}