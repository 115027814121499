export function parseQueryString (qs: string) : {[key: string]: any} {
    const result = {};
    if (qs && typeof qs === 'string') {
        if (qs.charAt(0) === '?') {
            qs = qs.substr(1);
        }

        const pairs = qs.split("&");

        for (const pair of pairs) {
            const [key, value] = pair.split("=");
            if (!key || !value) {
                continue;
            }
            result[decodeURIComponent(key)] = decodeURIComponent(value);
        }
    }

    return result;
}