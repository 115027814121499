import {Service} from "../interfaces/service";

export const Services: Array<Service> = [
    {
        displayName: "Semi-Custom Products",
        name: "semi-custom"
    },
    {
        displayName: "Customized Products",
        name: "custom"
    },
    {
        displayName: "Design and Laser Cutting Services",
        name: 'laser-cutting'
    },
];

const serviceMap = {};
for (const category of Services) {
    serviceMap[category.name] = category;
}

export function getServiceDescription (name: string): Service | null {
    return serviceMap[name] || null;
}
