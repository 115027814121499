import {store} from "../util/redux-store";
import {getPricingConfig, setPricingConfig, PricingConfig} from "../components/pricing/pricing.module";
import React, {useEffect} from "react";
import {useSelector} from "react-redux";

let promise;

export async function loadPricingConfig (): Promise<Readonly<PricingConfig>> {
    let result: Readonly<PricingConfig> = getPricingConfig(store.getState());

    if (!result) {
        promise = promise || fetch('/pricing.json').then(response => response.json()).then(result => {
            store.dispatch(setPricingConfig(result));
            return result;
        });
        result = await promise;
        promise = null;
    }

    return result;
}

export const usePricingConfig = () => {
    const config = useSelector(getPricingConfig);

    useEffect(() => {
        if (!config) {
            loadPricingConfig();
        }
    }, [config]);

    return config || null;
};