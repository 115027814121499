import * as React from 'react';
import {LinkContainer} from "react-router-bootstrap";
import {Link, Redirect} from "react-router-dom";
import {Navbar as BootstrapNavbar, NavDropdown} from "react-bootstrap";
import {getCartUrl, getHomeUrl} from "../util/url";
import {
    addBreakpointListener,
    Breakpoint,
    BreakpointDescription,
    getBreakpointDescription,
    getCurrentBreakpoint,
    removeBreakpointListener
} from "../util/breakpoint";
import {connect} from "react-redux";
import {getCartItems} from "./cart/cart.module";
import {toCurrencyFormat} from "../util/currency";
import {calculateCartItemPrice} from "../util/price-utils";
import {getPricingConfig} from "./pricing/pricing.module";
import {PricingContainer} from "./pricing/pricing";

export type Tab = {
    displayName: string;
    path: string;
    divider?: boolean;
    children?: Array<Tab>;
    collapse?: Breakpoint;
}

type NavBarProps = {
    tabs: Array<Tab>
}

function buildTab (tab: Tab, index) {
    return (
        <React.Fragment key={tab.displayName}>
            {tab.children?.length
                ? <span className='nav-item'>
                    <NavDropdown title={tab.displayName} id={`collapsible-nav-dropdown-${index}`}>
                        {tab.children.map((tab: Tab) =>
                            <React.Fragment key={tab.path}>
                                <LinkContainer to={tab.path}>
                                    <NavDropdown.Item eventKey={tab.path}>{tab.displayName}</NavDropdown.Item>
                                </LinkContainer>
                                {tab.divider && <NavDropdown.Divider/>}
                            </React.Fragment>
                        )}
                    </NavDropdown>
                </span>
                : <Link to={tab.path}>
                    <span className='nav-item'>{tab.displayName}</span>
                </Link>
            }
        </React.Fragment>
    )
}

class ConnectedCartNav extends React.Component<{productCount: number; total: number;}> {
    constructor(props) {
        super(props);
    }

    render () {
        const {productCount = 0, total = 0} = this.props;

        return <span className={'nav-item nav-cart'}>
                <Link to={getCartUrl()}>
                    <i className="material-icons">shopping_cart</i>
                    Cart ({productCount})<PricingContainer>{total ? ` [${toCurrencyFormat(total)}]` : ''}</PricingContainer>
                </Link>
            </span>;
    }
}

const mapStateToProps = (state) => {
    const pricingConfig = getPricingConfig(state);
    const items = getCartItems(state);
    let productCount = 0;
    let total = 0;

    for (const item of items) {
        productCount += item.quantity;
        if (pricingConfig) {
            total += calculateCartItemPrice(item);
        }
    }

    return {
        productCount,
        total,
        pricingConfig
    };
};

const CartNav = connect(mapStateToProps)(ConnectedCartNav) as any as React.ComponentClass<{}>;

export class Navbar extends React.Component<any, {breakpoint: BreakpointDescription}> {
    constructor (public props: NavBarProps) {
        super(props);

        this.state = {breakpoint: getCurrentBreakpoint()}
    }

    componentDidMount(): void {
        addBreakpointListener(this.onBreakpointChanged)
    }

    componentWillUnmount(): void {
        removeBreakpointListener(this.onBreakpointChanged);
    }

    protected onBreakpointChanged = (breakpoint: BreakpointDescription) => {
        if (breakpoint !== this.state.breakpoint) {
            this.setState({...this.state, breakpoint});
        }
    };

    render () {

        const collapsed = [], expanded = [];

        this.props.tabs.forEach((tab, index) => {
            const built = buildTab(tab, index);
            if (!tab.collapse || getBreakpointDescription(tab.collapse).maxWidth < this.state.breakpoint.maxWidth) {
                expanded.push(built);
            } else {
                collapsed.push(built);
            }
        });

        return <BootstrapNavbar sticky='top' expand='xl' variant="dark" collapseOnSelect={true}>

            <BootstrapNavbar.Brand>
                <Link to={getHomeUrl()}>Home</Link>
            </BootstrapNavbar.Brand>
            {expanded}
            {collapsed.length
                ? <BootstrapNavbar.Collapse>
                    {collapsed}
                </BootstrapNavbar.Collapse>
                : null
            }
            <CartNav />
            <BootstrapNavbar.Toggle aria-controls="responsive-navbar-nav" />
        </BootstrapNavbar>
    }
}