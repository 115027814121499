const Selector = '.grecaptcha-badge';
const Classname = 'shown';

let timeout;

export function hideCaptcha () {
    document.querySelector(Selector)?.classList.remove(Classname);
}

export function showCaptcha (duration = 5000) {
    document.querySelector(Selector)?.classList.add(Classname);
    if (duration) {
        clearTimeout(timeout);
        timeout = setTimeout(hideCaptcha, duration);
    }
}