import React from "react";
import {Link} from "react-router-dom";
import {getContactUrl, getImageUrl} from "../util/url";
import {MailingListForm} from "../components/mailing-list-form";
import {NewProduct} from "./landing-view/new-product";
import {Testimonials, testimonials} from "./landing-view/testimonials";

export class LandingView extends React.Component {
    render () {
        return <div className="landing-view">
            <NewProduct />

            <div className='section-header'>Who We Are</div>
                <div className='section-content'>
                    <p>Eckardt Design LLC is a provider of premium museum-quality scale 
                        model <i><b>wood</b></i> doors, windows, scenic details and kits, and custom laser 
                        cutting services, for the discriminating modeler since 2011. Whether you are a garden 
                        railroad modeler, an indoor modeler or a dollhouse builder, you will find parts to fill 
                        your needs.</p> </div>

            <div className='section-header'>Our Products</div>
	    <div className='section-content flex flex-row'>
                <div>
                    <p> All parts are laser cut from cedar and aircraft-grade birch. Doors, windows and other 
                        details are provided in kit form and are easy to assemble. For those who prefer a 
                        ready-to-install product, pre-assembled options are available for most items.</p>

                    <p>We offer a catalog of standard door and window offerings, but we also offer semi-custom 
                        doors and windows to fit your existing rough-openings or to meet your special size 
                        requirements <i>at the same price as the standard offerings</i>. All standard and 
                        semi-custom products are available in 1:20 and 1:24 scales.</p>

                    <p>Need another scale? Do you need acrylic parts? Not finding what you're looking 
                        for? <Link to={getContactUrl()}>Send us an inquiry</Link> about our affordable custom 
                        services so we can help you bring your worlds to life.</p>
                </div>
            <div>
                <img src={getImageUrl('window_samples.jpg')} className={'splash-image'} />
            </div>
	    </div>
		<MailingListForm/>
		<div className='section-header'>What Customers Are Saying</div>
		<Testimonials testimonials={testimonials}/>
      </div>
}}
