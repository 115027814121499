import React from "react";
import {Product} from "../../interfaces/catalog";
import {classNames} from "../../util/style-utils";
import {processTags} from "../../util/tag-processor";


export class ProductTable extends React.Component {
    constructor (public props: {product: Product, context: any}) {
        super(props);
    }

    render () {
        const {product} = this.props;
        return <>
            <div className="product-description">
                {product.dataTable && product.dataTable.sections && product.dataTable.sections.length &&
                    <div className={classNames('product-data-table', product.dataTable.classes)} style={product.dataTable.styles}>
                        {product.dataTable.sections.map((item, index) =>
                            <React.Fragment key={`data-section-${index}`}>
                                {item.label !== undefined ? <React.Fragment>
                                    <div className={'data-row'}>
                                        <div className={classNames('data-row-key', 'section-label', item.sectionLabelClasses)}>{item.label || ' '}</div>
                                        <div className={'data-row-value section-label'}>{' '}</div>
                                    </div>
                                </React.Fragment> : null}
                                {item.rows.map((row, rowIndex) => {
                                    const value = row.field ? product[row.field] : row.value;
                                    return <div className={'data-row'} key={`data-section-${index}-row-${rowIndex}`}>
                                        <div
                                            className={classNames('data-row-key', product.dataTable.labelClasses, row.labelClasses)}
                                            style={row.keyStyles}
                                        >
                                            {row.label ? processTags(row.label) : ' '}
                                        </div>
                                        <div className={classNames('data-row-value', row.valueClasses)}
                                             style={row.valueStyles}
                                             key={`data-section-${index}-row-value-${rowIndex}`}
                                        >
                                            {value === undefined ? ' ' : processTags(String(value))}
                                        </div>
                                    </div>
                                })}
                            </React.Fragment>
                        )}
                    </div>
                }
            </div>
        </>;
    }

}