import {bool} from "aws-sdk/clients/signer";

export function clone <T> (o: T) : T {
    return JSON.parse(JSON.stringify(o));
}

export function set <T = any, V = any> (o: T, key: string, value: V) : T {
    if (o && key) {
        const separatorIndex = key.indexOf(".");
        if (~separatorIndex) {
            const left = key.substring(0, separatorIndex);
            const right = key.substring(separatorIndex + 1);
            set(o[left] || (o[left] = {}), right, value);
        } else {
            o[key] = value;
        }
    }

    return o;
}

export function get <T = any, R = any> (o: T, key: string, defaultValue?: R) : R {
    if (o && key) {
        const separatorIndex = key.indexOf(".");
        if (~separatorIndex) {
            const left = key.substring(0, separatorIndex);
            const right = key.substring(separatorIndex + 1);
            return o[left] ? get(o[left], right, defaultValue) : defaultValue;
        } else {
            return typeof o[key] === 'undefined' ? defaultValue : o[key];
        }
    }

    return defaultValue;
}

function eq (test, first, second) {
    if (first === second) {
        return true;
    }

    if (!first || !second || typeof first !== 'object' || typeof second !== 'object') {
        return false;
    }

    const firstKeys = Reflect.ownKeys(first);
    if (firstKeys.length !== Reflect.ownKeys(second).length) {
        return false;
    }

    for (const key of firstKeys) {
        if (!(Reflect.has(second, key) && test(first[key], second[key]))) {
            return false;
        }
    }

    return true;
}

export const shallowEquals = eq.bind(null, (first, second) => first === second);

export const deepEquals = eq.bind(null, (first, second) => deepEquals(first, second));

export function deepClone (o) {
    return JSON.parse(JSON.stringify(o));
}

export function coalesce <T> (...values: Array<T | undefined | null>) : T {
    for (const value of values) {
        if (value !== undefined && value !== null) {
            return value;
        }
    }

    return null;
}