import {PricingConfigItem} from "../src/components/pricing/pricing.module";
import {Category} from "../src/interfaces/category";
import {coalesce} from "../src/util/object-utils";
import {lpad} from '../src/util/string-utils';

export const PricingInfo = {
    // These override any of the defaults (layered over defaults)
    20: {
	//priceBaseDoor:			8,
	//priceWide:			    2,		// material surcharge if width >48"
    },

    13: {
        priceBaseDoor:		25,
        priceBaseWindow:	21,
        priceDoubleDoor:	8,
        priceWide:		    5,			// material surcharge if width >48"
        priceFreightDoor:	8,
        priceTransom:		4,
        pricePerSidelight:	4,			// each
        pricePediment:		4,
    },

    // Anything not overridden by scale above uses these values
    default: {
        // 1:24
        priceBaseDoor:			8.5,
        priceBaseWindow:		7,
        priceWide:			    0,		// material surcharge if width >48"
        priceDoubleDoor:		3,
        priceFreightDoor:		4,		// surcharge for thick frame
        priceTransom:			1,		// surcharge for transom glazing
        pricePerSidelight:		1,		// surcharge for sidelight glazing, each
        pricePediment:			2,
	    priceOversized:        	1,		// material surcharge if window >40in2 or door >50in2

        // Assembly:
        baseAssemblyPrice:		5,
        assemblyFreight:		2,
        assemblyDouble:			3,
        assemblyWide:			1,		// width >48"
        assemblyOversized:		1,		// single up-charge if any are included
        assemblyPediment:		1,
        assemblyComplex:		1,		// arbitrary add-on, will ALWAYS be manually set

        // Kit modifiers
        // these can also be done as price overrides in the item definition files
        //priceKIt0001V1:                 5,
        //priceKIt0001V2:                 8,
        //priceKIt0001V3:                 10,
    }
};

enum PricingKey {
    Price = 'price',
    Assembly = 'assemblyPrice'
};

const OversizedIncrementMapping = {
    [Category.Door]: 50,
    [Category.Window]: 40
};

const DoorRules = [
    (opts) => {
        addPriceItem(opts, 'basePrice', opts.priceBaseDoor, true);
        opts.overized = false;
    },
    (opts) => {
        switch (opts.style) {
            case 'FR':
                addPriceItem(opts, 'freightDoor', opts.priceFreightDoor, true);
                addPriceItem(opts, 'assemblyFreight', opts.assemblyFreight, true, PricingKey.Assembly);
                break;
            case 'DO':
                addPriceItem(opts, 'doubleDoor', opts.priceDoubleDoor, true);
                addPriceItem(opts, 'assemblyDoubleDoor', opts.assemblyDouble, true, PricingKey.Assembly);
                break;
        }
    },
    (opts) => {
        if (!opts.sidelight) {
            return;
        }

        opts.oversized += true;
        addPriceItem(opts, 'sidelight', opts.sidelight === 'SB' ? opts.pricePerSidelight * 2 : opts.pricePerSidelight);
    }
];

const WindowRules = [
    (opts) => {
        addPriceItem(opts, 'basePrice', opts.priceBaseWindow)
        opts.overized = false;
    }
];

const KitRules = [
    (opts, complete, pricingConfig) => {
        if (opts.category === Category.Kit) {
            const baseKitId = `${Category.Kit}${lpad(opts.kitNum,4)}`;
            opts.baseKitId = baseKitId;
            Object.assign(opts, pricingConfig.overrides[baseKitId]?.default);
            Object.assign(opts, pricingConfig.overrides[baseKitId]?.[opts.scale]);
        }
    },
    (opts) => {
        if (opts.variant) {
            opts.price += opts[`price${opts.baseKitId}V${opts.variant}`] || 0;
        }
    },
    (opts, complete) => complete()
];

function addPriceItem (opts: Record<string, any>, itemName: string, amount: number, unique = false, key: PricingKey = PricingKey.Price) {
    if (!opts[key]) {
        opts[key] = 0;
    }
    
    if (!opts._price) {
        opts._price = {};
    }

    const price = opts._price;

    if (Reflect.has(price, itemName) && unique) {
        return;
    }

    amount = coalesce(amount, 0);

    opts[key] += amount;
    opts._price[itemName] = (opts._price[itemName] || 0) + amount;
}

export function calculateOversizeIncrements (category: Category, materialUsed: number) {
    const baseMaterialUsed = OversizedIncrementMapping[category];
    if (!baseMaterialUsed) return 0;

    return Math.max(0, Math.ceil((materialUsed - baseMaterialUsed) / 10));
}

export const PricingRules = [
    (opts, complete, pricingConfig) => {
        const baseProductId = opts.productId?.split('-')[0];
        const scale = opts.scale;
        const result = {
            ...(pricingConfig.default || {}),
            ...(pricingConfig[scale] || {}),
            ...(pricingConfig.overrides[baseProductId]?.default || {}),
            ...(pricingConfig.overrides[baseProductId]?.[scale] || {}),
            ...(pricingConfig.overrides[opts.productId]?.default || {}),
            ...(pricingConfig.overrides[opts.productId]?.[scale] || {})
        } as PricingConfigItem;
        return result;
    },
    (opts, complete) => {
        if (opts.price) {
            if (opts.assemblyAvailable && !opts.assemblyPrice) {
                throw new Error("assemblyPrice is required when price is overridden and assembly is available.");
            }

            opts._price = { price: opts.price };
            if (opts.assemblyAvailable) {
                opts._price.assemblyPrice = opts.assemblyPrice;
            }
            return complete();
        }

        if (opts.baseAssemblyPrice) {
            addPriceItem(opts, 'baseAssemblyPrice', opts.baseAssemblyPrice, true, PricingKey.Assembly);
        }
    },
    (opts) => {
        switch (opts.category) {
            case Category.Door:
                return DoorRules;
            case Category.Window:
                return WindowRules;
            case Category.Kit:
                return KitRules;
        }

        throw new Error("Unknown category: " + opts.category);
    },
    (opts) => {
        if (opts.transom) {
            addPriceItem(opts, 'transom', opts.priceTransom);
            opts.oversized = true;
        }
    },
    (opts) => {
        switch (opts.category) {
            case Category.Door:
                break;
            case Category.Window:
                opts.oversizeIncrements = Math.max(0, Math.ceil((opts.materialUsed - 40) / 10));
                break;
            default:
                return;
	    }

        const oversizedPrice = calculateOversizeIncrements(opts.category, opts.materialUsed) * opts.priceOversized;
        if (oversizedPrice) {
    	    addPriceItem(opts, 'oversized', oversizedPrice);
        }

        if (opts.oversized) {
            addPriceItem(opts, 'assemblyOversized', opts.assemblyOversized, true, PricingKey.Assembly);
        }

    }
];

export default PricingInfo;
