import React from 'react';
import {Button} from "react-bootstrap";
import {Product} from "../../interfaces/catalog";
import {addCartItem, CartItem} from "../../components/cart/cart.module";

import Form from "react-bootstrap/Form";
import StickyNavBar from "../../components/sticky-navbar";
import { getCatalogCategoryUrl, getCatalogProductUrl} from "../../util/url";
import {Link, Redirect} from "react-router-dom";
import {toCurrencyFormat} from "../../util/currency";
import {useDispatch} from "react-redux";
import {isCustomized} from "../../util/custom";

interface ProductButtonProps {
    product: Product;
    disableAddToCart?: boolean;
    validator?: (cartItem: CartItem) => boolean;
}

function createOptions () {
    const options = [];
    for (let i = 1; i <= 25; i++) {
        options.push(<option value={i} key={`option-quantity-${i}`}>{i}</option>);
    }
    return <React.Fragment>
        {options}
    </React.Fragment>
}

export const ProductButtons: React.FC<ProductButtonProps> = (props) => {
    const [state, setState] = React.useState<{redirect: any}>({ redirect: null });
    const dispatch = useDispatch();
    const {product, validator} = props;
    const selectRef = React.createRef<HTMLSelectElement>();
    
    const price =  product.price + (product.assembled ? product.assemblyPrice : 0);
    const quantity = Number(selectRef.current?.value || 1);
    const image = product.images?.[0];
    const cartItem = {
        ...product.addToCart,
        quantity,
        price,
        image: (image?.thumbnail || image?.url) as string,
    }

    const isValid = validator ? validator(cartItem) : false;

    const onAddClicked = () => {
        if (!isValid) return;
        dispatch(addCartItem(cartItem));

        setState({
            redirect: {
                cartItem
            }
        });
    };

    return <StickyNavBar className={'product-buttons'}>
        {
            product.modifiable && product.customizeTo
                ? <div className={'customize'}>
                    <Link to={getCatalogProductUrl((product as any).customizeTo) + `?from=${(product as any).productId}`}>
                        <Button className={'customize-btn btn-success'}>
                            <i className="material-icons">build</i>
                            Customize
                        </Button>
                    </Link>
                </div>
                : null
        }
        {product.addToCart
            ? <div className={'add-to-cart'}>
                <Form.Control
                    as={"select"}
                    ref={selectRef as any}
                >
                    {createOptions()}
                </Form.Control>
                <Button
                    onClick={() => onAddClicked()}
                    disabled={!isValid || (props.disableAddToCart ?? false)}
                >
                    <i className="material-icons">add_shopping_cart</i>
                    {isCustomized(product.addToCart?.metadata) ? "Get A Quote" : "Add To Cart"} ({toCurrencyFormat(price)})
                </Button>
                {
                    state.redirect ?
                        <Redirect to={{
                            pathname: getCatalogCategoryUrl(product.categories?.[0]),
                            state: state.redirect
                        }} />
                        : null
                }
            </div>
            : null
        }
    </StickyNavBar>
};