import {addReducer} from "../../util/redux-store";
import {get} from '../../util/object-utils';
import {ReactElement} from "react";
import * as uuid from 'uuid';

const Keyspace = "toasts";
/************* Interfaces **************/

interface ShowToastAction {
    type: ActionTypes.ShowMessage;
    payload: ToastItem;
}

interface HideToastAction {
    type: ActionTypes.HideMessage;
    payload: {
        id: string;
    };
}

type ToastAction = ShowToastAction | HideToastAction;

type ToastElement = string | ReactElement;

export interface ToastItem {
    id: string;
    body: ToastElement;
    header: ToastElement;
    className?: string;
    duration?: number;
}

interface ToastState {
    toasts: Readonly<Array<Readonly<ToastItem>>>;
}

/************* Actions **************/

enum ActionTypes {
    ShowMessage = "TOAST/SHOW_MESSAGE",
    HideMessage = "TOAST/HIDE_MESSAGE"
}

export const showMessage = (message: Omit<ToastItem, 'id'>) => (
    {
        type: ActionTypes.ShowMessage,
        payload: {
            id: uuid.v4(),
            ...message
        }
    }
);

export const hideMessage = (id: string | ToastItem) => (
    {
        type: ActionTypes.HideMessage,
        payload: {
            id: typeof id === 'string' ? id : id.id
        }
    }
);

/************* SELECTORS **************/

export const getToasts = state => {
    return (get(state, Keyspace) as ToastState)?.toasts || [];
};

/************* Reducer **************/

const InitialState: ToastState = {
    toasts: []
};

const reducer = (state = InitialState, action: ToastAction) => {
    switch (action?.type) {
        case ActionTypes.ShowMessage:
            return {
                ...state,
                toasts: state.toasts.concat(action.payload)
            };
        case ActionTypes.HideMessage:
            let found = -1;
            let index = 0;
            for (const item of state.toasts) {
                if (item.id === action.payload.id) {
                    found = index;
                    break;
                }
                index++;
            }

            return ~found ? {...state, toasts: state.toasts.slice(0, index).concat(state.toasts.slice(index + 1))} : state;
    }

    return state;
};

addReducer(reducer, Keyspace);