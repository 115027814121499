interface Product {
    displayName: string;
    name: string;
}

export const Products: Array<Product> = [
    {
        displayName: "Windows",
        name: "windows"
    },
    {
        displayName: "Doors",
        name: 'doors'
    },
    {
        displayName: "Crates",
        name: "crates"
    },
    {
        displayName: "Wagon Wheels",
        name: "wagonwheels"
    },
    {
        displayName: "Kits",
        name: "kits"
    },
];

const productsMap = {};
for (const family of Products) {
    productsMap[family.name] = family;
}

export function getProductsDescription (name: string): Product | null {
    return productsMap[name] || null;
}
