import * as React from "react";
import {getImageUrl} from "../util/url";
import {ContactForm} from "../components/contact-form";

const InquiryEmail = 'info@EckardtDesign.com';

export const ContactView: React.FC<{}> = () => {
    return <div className={'contact-view'}>
        <div className='section-header'>Contact Us</div>
        <div className='section-content flex flex-row'>
            <div className={'flex flex-col'}>
                <p>
                    Interested in custom laser-cutting services? Just want to know more? Please send us a message at <a href={`mailto:${InquiryEmail}`}>{InquiryEmail}</a> and we'll get back to you as soon as possible!
                </p>
                <ContactForm />
            </div>
            <img src={getImageUrl('crates_group_sd.jpg')} className={'splash-image'} />
        </div>
    </div>;
};