import * as React from "react";
import Navbar from "react-bootstrap/Navbar";

export const AppFooter = () => {
    const currentYear = new Date().getFullYear();

    let copyrightStamp = `2019-${currentYear}`;

    return <div className='footer'>
        <Navbar fixed='bottom'>
            All Content &copy;{copyrightStamp} Eckardt Design LLC. All rights reserved.
        </Navbar>
    </div>
}
