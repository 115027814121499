import {CatalogCategory, Product} from "../interfaces/catalog";
import {deepClone} from "../util/object-utils";
import {useEffect, useState} from "react";

let cache;

function sanitizeItem (item) {
    item.images = item.images || [];
    return item;
}

export function buildCatalog (catalog) {
    const templates = {}, items = [];
    for (const each of catalog) {
        templates[each.id] = deepClone(each);
        if (!each.template) {
            items.push(each);
        }
    }

    items.forEach((item, index) => {
        if (!item.parent) {
            sanitizeItem(item);
            return;
        }

        const parents = [];
        let next = item;
        while (next.parent) {
            parents.push(next.parent);
            if (templates[next.parent]) {
                next = templates[next.parent];
            } else {
                console.warn("No template found for name: " + next.parent);
            }
        }

        const result: any = {};
        for (let i = parents.length - 1; i >= 0; i--) {
            Object.assign(result, deepClone(templates[parents[i]]));
        }
        Object.assign(result, item);
        delete result.parent;
        delete result.template;
        items[index] = sanitizeItem(result);
    });

    return items.sort((first, second) => {
        if (first.priority === second.priority ||
            first.priorty === undefined && second.priority === undefined
        ) {
            return 0;
        } else if (first.priority === undefined) {
            return 1;
        } else if (second.priority === undefined) {
            return -1;
        } else if (first.priority > second.priority) {
            return 1;
        } else if (first.priority < second.priority) {
            return -1;
        }
    });
}

export async function loadCatalog (category?: CatalogCategory): Promise<Array<Product>> {
    if (!cache) {
        cache = await fetch('/catalog.json').then(response => response.json()).then(buildCatalog);
    }

    return category ? cache.filter(item => item.categories && item.categories.includes(category)) : cache;
}

export async function loadProduct (productId): Promise<Product> {
    const catalog = await loadCatalog();
    return catalog && catalog.find(item => item.id === productId) || null;
}

export const useCatalog = (category?: CatalogCategory) => {
    const [catalog, setCatalog] = useState<Array<Product> | null>(cache);

    useEffect(() => {
        if (!cache) {
            loadCatalog().then(loaded => setCatalog(loaded));
        }
    }, [category, cache]);

    return category ? catalog.filter(item => item.categories && item.categories.includes(category)) : catalog;
};

export const useProduct = (productId) => {
    const catalog = useCatalog();

    return findProductFromCatalog(catalog, productId);
};

export const findProductFromCatalog = (catalog, productId) => {
    return catalog?.find(item => item.id === productId) ?? null
};