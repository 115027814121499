import * as React from 'react';
import {loadCatalog} from "../network/catalog";
import {ErrorView} from "./error-view";
import {Product} from "../interfaces/catalog";
import {CatalogItem} from "./catalog-view/catalog-item";
import {getCategoryDescription} from "../util/categories";
import {getPersistentStore, PersistentStore} from "../util/persistent-store";
import {RouteProps, withRouter} from "react-router";
import {Link} from "react-router-dom";
import {getCartUrl, getCatalogImageUrl} from "../util/url";
import {Button} from "react-bootstrap";
import {Spinner} from "../components/spinner";

enum State {
    Loading,
    Loaded,
    Error
}

interface CategoryViewProps {
    categoryId?: string
}

interface CategoryViewState {
    state: State;
    catalog?: Array<Product>;
    error?: Error;
    categoryId?: string;
}

class CategoryViewWithLocation extends React.Component<CategoryViewProps & RouteProps, CategoryViewState> {
    constructor (public props) {
        super(props);

        this.state = {
            state: State.Loading,
            catalog: null,
            error: null,
            categoryId: null
        };

        this.loadCatalog(props);
    }

    private loadCatalog (props) {
        const categoryId = props.categoryId;
        loadCatalog(categoryId).then((catalog) => {
            this.setState({state: State.Loaded, catalog, categoryId});
        }).catch(error => {
            this.setState({state: State.Error, error});
        });
    }

    componentWillReceiveProps(nextProps: Readonly<CategoryViewProps>, nextContext: any): void {
        if (this.state.state !== State.Loading) {
            this.setState({state: State.Loading, catalog: []});
        }

        this.loadCatalog(nextProps);
    }

    render () {
        const {state, catalog, categoryId} = this.state;
        const title = categoryId ? getCategoryDescription(categoryId).displayName : 'Catalog';
        if (state === State.Error) {
            return <ErrorView code={500} error={this.state.error}/>;
        }

        const context = Object.assign({}, getPersistentStore(PersistentStore.Attributes).toJSON());

        const cartItem = this.props.location.state?.cartItem;

        return <div className={'category-view'}>
            {cartItem ?
                <div className={'catalog-content cart-item-added-banner'}>
                    {/*<div className={'item-image'}>*/}
                    {/*</div>*/}
                    {/*<div className={'item-description'}>*/}
                    {/*</div>*/}
                    <img src={getCatalogImageUrl(cartItem.image)} alt={cartItem.product} />
                    <span className={'item-added-description'}>
                        ({cartItem.quantity}) of {cartItem.product} has been added to your cart!
                    </span>
                    <Link to={getCartUrl()}>
                        <Button>
                            <i className="material-icons">add_shopping_cart</i>
                            Proceed to checkout
                        </Button>
                    </Link>
                </div>
                : null
            }
            <div className='catalog-content'>
                <div className='catalog-title'>{title}</div>
                {state === State.Loaded ?
                    <div className={`catalog-items`}>
                        {catalog.map(item =>
                            <CatalogItem item={item} key={item.id} context={Object.assign({}, item, context)}/>
                        )}
                    </div>
                    : <div className='catalog-items loading'>
                        <Spinner />
                        <div className='loading-text'>Loading items...</div>
                    </div>
                }
            </div>
        </div>
    }
}

export const CategoryView = withRouter(CategoryViewWithLocation);