import * as React from 'react';
import {Product, ProductAttribute} from "../../interfaces/catalog";
import {ProductAttributeForm} from "./product-attribute-form";
import {ValidationState} from "../../util/validate";
import { processTags } from '../../util/tag-processor';

interface ProductInfoProps {
    product: Product,
    onAttributeUpdate: (attribute: ProductAttribute, value: any, state: ValidationState) => any;
}

export const ProductInfo: React.FC<ProductInfoProps> = (props) => {
    const {product, onAttributeUpdate} = props;

    const description = ((Array.isArray(product.description) ? product.description : [product.description]) as Array<string>)
        .map((p, index) => <p key={index}>{processTags(p)}</p>);

    return <>
        {product.description
            ? <div className={'product-description'}>
                {description}
            </div>
            : null
        }
        {
            product.attributes
                ? <ProductAttributeForm
                    attributes={product.attributes as Array<ProductAttribute>}
                    context={product}
                    onChange={onAttributeUpdate}
                />
                : null
        }
    </>;
};