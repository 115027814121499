import React, {reactElement, useState} from "react";
import {getTestimonialsImageUrl} from "../../util/url";

interface ReviewImage {
	thumbnail: string;
	expanded?: string;
}

interface Testimonial {
    reviewNumber: number;
    reviewHeader: ReactElement;
    reviewImage?: ReviewImage;
    review: ReactElement;
    author: string;
}

export const testimonials: Array<Testimonial> = [
    {
		reviewNumber: 1,
		reviewHeader: <div>I confidently recommend Eckardt Design.</div>,
		review: <div><q>I have been using Eckardt Design Laser Art products for the past couple of years. Eckardt Design is able to produce all manors of difficult products, windows, doors, specialty items such as lattice panels, eve brackets, corbels, etc..all of which I have used on a number of garden railway projects as well as kits I produce for myself and others. I find the custom work to be precise, excellent quality, affordable, timely, and easy to assemble. Joe is willing and able to help you solve design and assembly issues. I am confident that Eckardt Design can help with your custom, one of a kind projects as well as to be a great source for all your window and door needs. I confidently recommend Eckardt Design.</q></div>,
		author: "Gary Lee, Master Model Railroader, Corbett, OR"
    },

    {
		reviewNumber: 2,
		reviewHeader: <div>&hellip;a job well done.</div>,
		reviewImage: {
			thumbnail: "TJ_Meyer_pic_sm.jpg",
			expanded: "TJ_Meyer_pic.jpg"
		},
		review: <div><q>After seeing the quality and precision of the doors and windows that Joe provides I wanted nothing less for my scratch built models. My Camp Sherman country store had to have the perfect windows and doors that are actually on the bulding. The ones you did for me were perfect. They are the finishing touches to the buildings that cannot be duplicated by plastic or trying to hand cut some of the fine detail. Thanks Joe for a job well done.</q></div>,
		author: "TJ Meyer, Milwaukie, OR"
    },

    {
		reviewNumber: 3,
		reviewHeader: <div>&hellip;best looking scale doors and windows&hellip;</div>,
		reviewImage: {
			thumbnail: "Bill_pic_sm.jpg",
			expanded: "Bill_pic.jpg"
		},
		review: <div>
			<q>Eckardt Design has built over 100 scale windows and a few doors for my G-Scale outdoor railroad.  He weatherproofed them with urethane and will remain outside year around.  In my opinion, they are the best looking scale doors and windows that are commercially available.</q>
			<br />
			<br />
			<q>Joe also engraved the award trophies used in the model contest for the 35th National Garden Railway Convention held in Portland Oregon in August 2019 which were made of 2-inch thick acrylic.  They were best looking award plagues I have seen.</q>
		</div>,
		author: "Bill Derville, Beaverton, OR"
    },

    {
		reviewNumber: 4,
		reviewHeader: <div>Highly recommended&hellip;</div>,
		review: <div><q>For two years we worked with Eckardt Design to consult on our laser engraving products.  We would work together to define the items, then Joe created the final product layout for the laser cutter. We found Joe very easy to work with - always listening to our ideas, then providing valuable suggestions that improved both our processes and our final products.  Joe's attention to detail is amazing - each piece was so meticulously designed that final fitting was a breeze.  Highly recommended, and looking forward to future work together.</q></div>,
		author: "Mark Dean, Redondo Beach, CA"
    },

    {
		reviewNumber: 5,
		reviewHeader: <div>Wonderful product for a modeler</div>,
		review: <div>
			<q>Last year I took on the task of remodeling a 30+-year-old Train Station that was originally scratch-built by a well-known respected model builder. The many windows and doors were either broken or no longer went with the 'new look' of the station. Joe was a guest at an open house that we hosted and ended up spending a good deal of time measuring and drawing up plans for custom windows and doors for the train station as well as for a Hunting Cabin acquired off the bargaining table at a local Garden Center. The finished product and quality of 18 windows and doors far exceeded my expectations. I would recommend Eckardt Design, LLC to anyone requiring a custom detailed product for their one of a kind projects.</q>
		</div>,
		author: "Linda Loudon, Vancouver, WA"
    },

    {
		reviewNumber: 6,
		reviewHeader: <div>Windows are superior in both detail and quality</div>,
		reviewImage: {
			thumbnail: "Shady_Rest_pic_sm.jpg",
			expanded: "Shady_Rest_pic.jpg"
		},
		review: <div><q>This fine model for my railroad started with the 4 main walls of laser cut MDF. They were cut to exact size and had the siding lines laser cut into their faces. Along with the siding lines all of the openings for the many doors and windows were also cut be the laser. This saved so much time and made the installation of the windows and doors so much easier. The signature wood front door along with the beautiful round top window were also laser cut. Joes windows are just superior in both detail and quality. Overall great quality in all that he did.</q></div>,
		author: "Mike Fisher, Tigard, OR"
    }

];

const ReviewImage: React.FC<{image: ReviewImage}> = function (props) {
	if (!props.image) {
		return null;
	}

	const {thumbnail, expanded} = props.image;

	let img = <img src={getTestimonialsImageUrl(thumbnail)} className={"testimonial-image"} />;

	if (expanded) {
		img = <a href={getTestimonialsImageUrl(expanded)} target="_blank">{img}</a>;
	}

	return img;
};

export const Testimonials : React.FC< {testimonials: Array<Testimonial>}> = function  (props) {
    const [state, setState] = useState({});

    return <div className={"testimonials-wrapper"}>
        <div className={"testimonials"}>
            {props.testimonials.map((item, key) =>
				<div
					key={`testimonial-${key}`}
                    // className={"testimonial" + (state[key] === true ? ' expanded' : "")}
                    className={"testimonial blockquote"}
                    onClick={() => setState({...state, [key]: !state[key]})}
                >
                    {/*<div className={"short-text"}>{item.reviewHeader}</div>*/}
                    {/*<div className={"long-text"}>{item.review}</div>*/}
                    {/*<div className={"author"}>{item.author}</div>*/}
					<div className={'paragraph bold'}>{item.reviewHeader}</div>
					<ReviewImage image={item.reviewImage}/>
                    <div className={'paragraph'}>{item.review}</div>
                    <footer>{item.author}</footer>
                </div>
            )}
        </div>
    </div>
};
